import React from "react";

const ContactForm: React.FC = () => {
  return (
    <form className="flex flex-col px-8 pt-7 pb-8 w-full bg-white rounded shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col pb-6 w-full max-md:max-w-full">
        <label htmlFor="name" className="text-base font-medium leading-loose text-gray-800">
          Name
        </label>
        <input
          type="text"
          id="name"
          className="flex w-full bg-white rounded border border-gray-300 border-solid max-w-[675px] min-h-[44px] max-md:max-w-full"
        />

        <label htmlFor="company" className="text-base font-medium leading-loose text-gray-800">
          Company
        </label>
        <input
          type="text"
          id="company"
          className="flex w-full bg-white rounded border border-gray-300 border-solid max-w-[675px] min-h-[43px] max-md:max-w-full"
        />

        <label htmlFor="jobTitle" className="text-base font-medium leading-loose text-gray-800">
          Job Title
        </label>
        <input
          type="text"
          id="jobTitle"
          className="flex w-full bg-white rounded border border-gray-300 border-solid max-w-[675px] min-h-[43px] max-md:max-w-full"
        />

        <label htmlFor="workEmail" className="text-base font-medium leading-loose text-gray-800">
          Work Email
        </label>
        <input
          type="email"
          id="workEmail"
          className="flex w-full bg-white rounded border border-gray-300 border-solid max-w-[675px] min-h-[44px] max-md:max-w-full"
        />

        <label htmlFor="phoneNumber" className="text-base font-medium leading-loose text-gray-800">
          Phone Number
        </label>
        <input
          type="tel"
          id="phoneNumber"
          className="flex w-full bg-white rounded border border-gray-300 border-solid max-w-[675px] min-h-[43px] max-md:max-w-full"
        />

        <label htmlFor="clearingProduct" className="text-base font-medium leading-loose text-gray-800">
          Clearing Product
        </label>
        <div className="flex flex-col pb-3 w-full bg-white rounded border border-gray-300 border-solid max-w-[675px] min-h-[43px] max-md:max-w-full">
          <select
            id="clearingProduct"
            className=" z-10 mx-3 mt-0 text-base font-medium leading-5 text-gray-800 max-md:mr-2.5 max-md:max-w-full"
          >
            <option value="">Please Select</option>
          </select>
        </div>
        <div className="max-w-[675px] min-h-[43px] max-md:max-w-full">
        </div>
        <button
          type="submit"
          className="py-4 pr-6 pl-6 max-w-full  text-base font-extrabold text-center text-white uppercase whitespace-nowrap bg-sky-500 rounded-[50px] tracking-[3px] w-[122px] max-md:px-5"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactForm;