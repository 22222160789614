/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface ProductCardProps {
  icon: string;
  title: string;
  description: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col pt-6 pr-9 mx-auto w-full bg-white rounded-sm shadow-sm min-h-[159px] min-w-[304px] max-md:mt-6">
        <div className="flex items-start pb-2 w-full">
          <div className="flex flex-col pb-8 w-24">
            <div className="flex flex-col justify-center px-6 w-24 max-md:px-5">
              <img
                loading="lazy"
                src={icon}
                className="object-contain w-full aspect-square"
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 shrink pb-6 basis-0 min-w-[240px] text-neutral-900">
            <h3 className="w-full text-2xl leading-8 whitespace-nowrap">
              {title}
            </h3>
            <p className="mt-2 w-full text-sm leading-5">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
