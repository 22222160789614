/**
 * This code was generated by Builder.io.
 */
import React from 'react';

const NetworkSection: React.FC = () => {
  return (
    <section className="flex flex-wrap gap-20 items-center py-2 pr-96 pl-72 mt-14 w-full font-semibold leading-8 bg-neutral-100 text-neutral-900 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col grow shrink self-stretch my-auto min-w-[240px] w-[492px] max-md:max-w-full">
        <h2 className="w-full text-4xl tracking-tighter leading-10 max-md:max-w-full">
          Connect to a growing network of <br /> 100+ banks
        </h2>
        <p className="mt-5 text-xl max-md:max-w-full">
          Plaid enables you to tap into the industry's largest network of <br /> banks, fintechs, and consumers. So you can onboard new <br /> customers, reduce fraud, and increase the lifetime value of <br /> customers across the user journey.
        </p>
        <ul className="flex flex-col pt-10 mt-5 w-full text-xl max-md:max-w-full">
          {[
            "100M+ global users",
            "12,000+ financial institutions in 17 countries",
            "8,000+ digital finance apps and services"
          ].map((item, index) => (
            <li key={index} className="flex flex-wrap gap-3 py-1.5 w-full max-md:max-w-full">
              <img loading="lazy" src={`http://b.io/ext_${7 + index}-`} alt="" className="object-contain shrink-0 self-start w-4 aspect-square" />
              <div className="flex-auto w-[579px] max-md:max-w-full">{item}</div>
            </li>
          ))}
        </ul>
      </div>
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/58f5b53cb4e3644b7864a6e02fc50285fbe9b658e5710136aad6d5cd506b862e?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31" alt="Network illustration" className="object-contain grow shrink self-stretch my-auto aspect-square max-w-[678px] min-w-[240px] w-[469px] max-md:max-w-full" />
    </section>
  );
};

export default NetworkSection;
