/**
 * This code was generated by Builder.io.
 */
import React from "react";
import SidebarItem from "./SidebarItem";

const sidebarItems = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/13e34031a42bbc833d105e7b976bcb3c639522fdea3ca658c5aecce6e6fde4ef?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Home",
    isActive: true,
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e3a00a935faa78241c8d9b6d89f8c8ce2adb2d3a9079a959456b365b66728472?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Quickstart",
    link: "",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ec0b243d33e41023acd5666cf456d632129774ac9a1f5fb807ffabdf6ec5fb61?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "API",
    link: "https://fintechain.com/docs/api/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3b065c362f7e95163d76c92a7d8652a7b60e7382ef24f64e19af8b5f7b5f93e4?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Link",
    link: "https://fintechain.com/docs/link/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fcb48a0c6678aa19f887308cc016497931de09dde2ae25a30b46a1b8453c3023?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Sandbox",
    link: "https://fintechain.com/docs/sandbox/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5b3e5ac2ed8ebafa69d50f0ca9e43a1797aa35412a7e4f39cff6fddfb164885b?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Resources",
    link: "https://fintechain.com/docs/resources/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f7b5f6b0806460afe4c7230a92c40370eaa9c75c2088a6692d4112e0470f7fb4?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Errors",
    link: "https://fintechain.com/docs/errors/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/262fe67a4fd8353ac55d7ce24a746c7137f35f8cc511c6dd3410f876240a5eb6?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Launch checklist",
    link: "https://fintechain.com/docs/launch-checklist/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0c4c74c6ea1cce45e222e86accf9c71ae959a326791ace7e85d9eba57a731f84?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Account",
    link: "https://fintechain.com/docs/account/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/07842bc52d15868d1e852189cb53a18bcf03cae3dfa48e08537ec93c0e2d7450?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Support",
    link: "https://fintechain.com/docs/support/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3818029a9aac42c6b160a8801f0e544ebb4bf52e4b5bc3f4a1e84ae757b910a2?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "Changelog",
    link: "https://fintechain.com/docs/changelog/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f2cde6746e7b02f9459a984475dc50a7ff7c85580d4e4a2434db7276723805ca?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    text: "fintechain Check",
    link: "https://fintechain.com/docs/check/",
  },
];

const Sidebar: React.FC = () => {
  return (
    <aside className="flex flex-col grow shrink justify-center w-64 bg-neutral-50 min-w-[320px]">
      <div className="flex flex-col flex-1 w-80 max-w-full">
        <div className="flex flex-col justify-center py-7 w-full whitespace-nowrap min-h-[92px]">
          <div className="flex flex-col justify-center py-7 pl-4 w-full whitespace-nowrap min-h-[92px]"> {/* 添加 pl-4 确保左侧填充 */}
            <div className="flex gap-px px-0.5 py-1 w-full">
              <a href="/" className="flex items-center"> {/* 使用 <a> 标签包裹图片和文字 */}
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ef25aebc3c1ce1867efd4d48fc6131ae6f1acc45a89728467f19986c7a31ba9?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                  className="object-contain shrink-0 my-auto aspect-[0.85] w-[11px]"
                  alt=""
                />
                <div className="flex items-end ml-2">Fintechain</div>
              </a>

              <div className="self-end gap-px  pl-2 my-auto">
              </div>
              Docs
            </div> {/* 保持 pl-2 增加间距 */}
          </div>
        </div>
        <nav className="flex overflow-hidden flex-col flex-1 pb-6 w-full">
          <ul className="flex flex-col w-full">
            {sidebarItems.map((item, index) => (
              <SidebarItem key={index} {...item} />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
