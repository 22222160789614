/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface CardProps {
  icon: string;
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
}

const Card: React.FC<CardProps> = ({ icon, title, description, linkText, linkUrl }) => {
  return (
    <div className="flex flex-col items-start px-8 pt-10 pb-28 mx-auto w-full bg-stone-50 min-h-[285px] max-md:px-5 max-md:pb-24 max-md:mt-6">
      <div
        className=" text-[12px] text-gray-400 justify-start text-start object-contain max-w-full"
      >
        {icon}
      </div>
      <div className="flex flex-col items-start self-stretch mt-4 w-full">
        <div
          className=" text-[18px] justify-start text-start object-contain max-w-full"
        >
          {title}
        </div>
      </div>
      <div
        className=" text-[15px] mt-8 text-gray-900 justify-start text-start object-contain max-w-full"
      >
        {description}
      </div>
      <div className="flex gap-2 items-center mt-4 h-[17px] rounded-[50px]">
        <a href={linkUrl} className="self-stretch my-auto text-sm leading-4 underline text-neutral-700" target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
        <div className="flex flex-col self-stretch my-auto min-h-[11px] w-[7px]">
          <div className="flex overflow-hidden flex-col justify-center items-center min-h-[11px] w-[7px]">
            <svg className="object-contain w-full aspect-[0.64]" xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <g clip-path="url(#clip0_273_1749)">
                <path d="M1.49995 11.9183C1.39995 11.9183 1.19995 11.8183 1.09995 11.7183C0.899951 11.5183 0.899951 11.1183 1.09995 10.9183L5.59995 6.41826L1.09995 1.91826C0.899951 1.71826 0.899951 1.31826 1.09995 1.11826C1.39995 0.918262 1.69995 0.918262 1.89995 1.11826L6.79995 6.01826C6.99995 6.21826 6.99995 6.61826 6.79995 6.81826L1.89995 11.7183C1.79995 11.8183 1.69995 11.9183 1.49995 11.9183Z" fill="#333D3E" />
              </g>
              <defs>
                <clipPath id="clip0_273_1749">
                  <rect width="7" height="11" fill="white" transform="translate(0 0.918457)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};


const SearchCard: React.FC = () => {
  return (
    <div className="flex flex-col px-14 pt-8 pb-20 mx-auto w-full bg-white border border-gray-200 border-solid min-h-[285px] max-md:px-5 max-md:mt-6">
      <div
        className=" text-[12px] text-gray-400 justify-start text-start object-contain max-w-full"
      >
        FIA
      </div>
      <div
        className=" text-[20px] justify-start text-start object-contain max-w-full"
      >
        Fintechain Identity Address
      </div>
      <form className="h-1/4 flex relative flex-col mt-4 w-full max-w-[300px]">
        <div className="flex overflow-hidden z-0 flex-col justify-center py-5 pr-16 pl-4 w-full bg-white border border-gray-200 border-solid min-h-[46px] rounded-[50px] max-md:pr-5">
          <label htmlFor="searchInput" className="sr-only">Search</label>
          <input
            type="text"
            id="searchInput"
            className="w-full bg-transparent border-none focus:outline-none"
            placeholder="Search"
            aria-label="Search"
          />
        </div>
        <button type="submit" className="flex overflow-hidden absolute right-0 bottom-0 z-0 flex-col bg-cyan-400 h-[46px] min-h-[46px] rounded-[50px] w-[50px]">
          <div className="flex overflow-hidden flex-col justify-center items-center px-4 py-4 w-full min-h-[46px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 15 15" fill="none">
              <g clip-path="url(#clip0_146_1980)">
                <path d="M11.88 10.3203L16.98 15.4203L15.58 16.8203L10.48 11.7203C9.47998 12.4203 8.27998 12.8203 6.97998 12.8203C3.67998 12.8203 0.97998 10.1203 0.97998 6.82031C0.97998 3.52031 3.67998 0.820312 6.97998 0.820312C10.28 0.820312 12.98 3.52031 12.98 6.82031C12.98 8.12031 12.58 9.32031 11.88 10.3203ZM6.97998 10.8203C9.17998 10.8203 10.98 9.02031 10.98 6.82031C10.98 4.62031 9.17998 2.82031 6.97998 2.82031C4.77998 2.82031 2.97998 4.62031 2.97998 6.82031C2.97998 9.02031 4.77998 10.8203 6.97998 10.8203Z" fill="#333D3E" />
              </g>
            </svg>          </div>
        </button>
      </form>
      <div className="flex gap-2 items-center self-start pt-5 mt-4 rounded-[50px]">
        <a href="#" className="self-stretch my-auto text-sm leading-tight text-neutral-700">
          Discover our reference data solutions
        </a>
        <div className="flex flex-col self-stretch my-auto min-h-[11px] w-[7px]">
          <div className="flex overflow-hidden flex-col justify-center items-center min-h-[11px] w-[7px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <g clip-path="url(#clip0_273_1946)">
                <path d="M1.49995 11.91C1.39995 11.91 1.19995 11.81 1.09995 11.71C0.899951 11.51 0.899951 11.11 1.09995 10.91L5.59995 6.40996L1.09995 1.90996C0.899951 1.70996 0.899951 1.30996 1.09995 1.10996C1.39995 0.909961 1.69995 0.909961 1.89995 1.10996L6.79995 6.00996C6.99995 6.20996 6.99995 6.60996 6.79995 6.80996L1.89995 11.71C1.79995 11.81 1.69995 11.91 1.49995 11.91Z" fill="#333D3E" />
              </g>
              <defs>
                <clipPath id="clip0_273_1946">
                  <rect width="7" height="11" fill="white" transform="translate(0 0.910156)" />
                </clipPath>
              </defs>
            </svg>          </div>
        </div>
      </div>
    </div>
  );
};


const ServiceCards: React.FC = () => {
  const cardData = [
    {
      icon: "Fintechain API",
      title: "API Service",
      description: "Reinforcing the security of the global banking system",
      linkText: "Learn more",
      linkUrl: "#"
    },
    {
      icon: "Decentralized System",
      title: "Messaging Ledger",
      description: "For more information, please visit the Knowledge Center",
      linkText: "Learn more",
      linkUrl: "#"
    }
  ];

  return (
    <main className="mt-11 mx-auto max-w-full w-[1141px] max-md:mt-10">
      <section className="flex gap-5 max-md:flex-col">
        {cardData.map((card, index) => (
          <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <Card {...card} />
          </div>
        ))}
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <SearchCard />
        </div>
      </section>
    </main>
  );
};

export default ServiceCards;