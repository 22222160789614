/**
 * This code was generated by Builder.io.
 */
import React from "react";
import MarketSegment from "./MarketSegment";
import IndustrialChallenge from "./IndustrialChallenge";
import IndustrySegment from "./IndustrySegment";
import { Link } from "react-router-dom";

interface YourNeedsProps {}

const YourNeeds: React.FC<YourNeedsProps> = () => {
  const marketSegments = [
    {
      title: "Banking",
      description:
        "Fintechain empowers banks to conduct transactions with enhanced safety and efficiency through a cutting-edge decentralized system.",
      icon: "./ms1.png",
    },
    {
      title: "Financial institutions",
      description:
        "Financial institutions can seamlessly trade assets in real time by utilizing the Fintechain clearing network.",
      icon: "./ms2.png",
    },
    {
      title: "B2B Service",
      description:
        "Corporate customers leverage the Fintechain network to implement secure payments at a low cost.",
      icon: "./ms3.png",
    },
    {
      title: "Risk Management",
      description:
        "Fintechain integrates KYC and AML services to ensure that all transactions comply with regulatory requirements, providing secure and compliant payment processing for its users.",
      icon: "./ms4.png",
    },
  ];

  const industrialChallenges = [
    {
      title: "Payments",
      description:
        "We're here to help you transact securely and reliably, comply with regulation, improve operational efficiency and innovate at scale to serve your customers better.",
      image:
        "./idsc1.png",
    },
    {
      title: "Financial crime compliance & cyber-security",
      description:
        "As regulation becomes more robust and cyber-threats more present, businesses need to demonstrate that their compliance programmes are effective.",
      image:
        "./idsc2.png",
    },
    {
      title: "Data",
      description: "Shaping the future of the financial industry.",
      image:
        "./idsc3.png",
    },
  ];

  const industrySegments = [
    {
      title: "Capital Markets",
      description:
        "The pressure to increase the efficiency of your operations and reduce costs is relentless. We're continuously working to address the regulatory demands and competition you're facing, and investigating the new technology landscape for your operations.",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/c33e91d4ecf1963acbf657ff5510a0328511ac43f8daf96af98208b399921c38?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    },
    {
      title: "Market Infrastructures",
      description:
        "Macro-level changes are affecting the financial markets on every level, and Financial Market Infrastructures (FMIs) need to respond to the community's emerging needs.",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/71a1e39298fd35aa34f3490a737f2023c0e218110c74c9534d4cc8d1a49a4d57?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    },
    {
      title: "Corporate treasury",
      description:
        "Treasury plays a crucial role in supporting financial objectives and informing strategic decisions.",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/a0c8ee4fdf39460e9ec68854badbe7adb97fb9874f6c7983067bd80dfe843897?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    },
  ];

  return (
    <div className="flex flex-col items-center px-20 max-md:px-5">
      <div className="flex flex-col px-4 max-w-full min-h-[4778px] w-5/6">
        <header className="flex flex-col w-full max-md:max-w-full">
          <nav className="flex flex-col items-center w-full max-md:max-w-full">
            <div className="flex flex-col justify-center w-full">
              <div className="flex flex-col flex-1 w-full max-w-[1167px] max-md:max-w-full">
                <div className="flex flex-col justify-center pb-10 w-full min-h-[83px] max-md:max-w-full">
                  <div className="flex pt-5 pr-3.5 pl-3.5 w-full min-h-[43px] max-md:max-w-full">
                    <div className="flex flex-col">
                      <div className="flex w-full">
                        <div className="flex flex-col text-xs leading-relaxed whitespace-nowrap text-slate-500">
                          <Link to="/" className="cursor-pointer">Home</Link>
                        </div>
                        <div className="flex overflow-hidden gap-2.5 pr-0.5 pl-1.5 -ml-0.5 h-full max-w-[111px] w-[143px]">
                          <div className="my-auto text-xs leading-5 text-neutral-700">
                            {" "}
                            ›{" "}
                          </div>
                          <div className="pr-1 pl-0 text-xs leading-relaxed text-slate-500" onClick={() => window.location.reload()} >
                            {" "}
                            Your needs{""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col">
                    <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
                      <h1 className="flex flex-col grow items-center px-3.5 text-7xl leading-none min-h-[112px] text-neutral-700 max-md:max-w-full max-md:text-4xl">
                        <div className="flex flex-col pb-9 max-w-full w-[654px] max-md:text-4xl">
                          <div className="w-full max-md:max-w-full max-md:text-4xl">
                            {" "}
                            Your Needs{" "}
                          </div>
                        </div>
                      </h1>
                    </div>
                    <div className="flex flex-col ml-20 w-[40%] max-md:ml-0 max-md:w-full">
                      <form className="flex grow items-center py-8 pr-3.5 pl-3.5 text-sm leading-relaxed text-center min-h-[112px] text-neutral-700 max-md:max-w-full">
                        <div className="flex flex-col items-end self-stretch my-auto">
                          <label htmlFor="emailInput" className="sr-only">
                            Enter your email
                          </label>
                          <input
                            type="email"
                            id="emailInput"
                            className="self-stretch px-8 py-3.5 bg-teal-200 border border-teal-200 border-solid min-h-[50px] rounded-[50px] max-md:px-5"
                            placeholder="Receive updates subscribe"
                            aria-label="Receive updates subscribe"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <main>
          <section className="flex flex-col items-start self-center w-full">
            <h2 className="flex z-10 flex-col justify-center self-center pb-6 w-full text-4xl leading-snug min-h-[101px] text-neutral-700">
              <div className="flex justify-center items-center pr-3.5 pl-3.5 w-full max-w-[1167px] min-h-[76px] max-md:max-w-full">
                <div className="flex flex-col flex-1 shrink self-stretch pb-5 my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="w-full max-md:max-w-full">
                    {" "}
                    Market segment{" "}
                  </div>
                </div>
              </div>
            </h2>
            <div className="flex flex-col justify-center items-center px-16 py-10 w-full bg-cyan-400 bg-opacity-40 max-md:px-5 max-md:max-w-full">
              <div className="w-full">
                <div className="flex gap-5 max-md:flex-col">
                  {marketSegments.map((segment, index) => (
                    <MarketSegment key={index} {...segment} />
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-wrap  self-center pt-16 w-full text-5xl leading-snug min-h-[98px] pr-[486px] text-neutral-700 max-md:text-4xl">
            <h2 className="flex flex-col flex-1 shrink pr-12 pl-3.5 w-full basis-0 max-w-[681px] min-w-[240px] max-md:pr-5 max-md:max-w-full">
              <div className="w-full max-md:max-w-full">
                {" "}
                Industrial current challenges{" "}
              </div>
            </h2>
          </section>
          <div className="flex flex-wrap gap-10 justify-center w-full">
          {industrialChallenges.map((challenge, index) => (
            <IndustrialChallenge key={index} {...challenge} />
          ))}
          </div>
          <section className="flex flex-col justify-center self-center pt-16 w-full text-5xl leading-snug text-neutral-700 max-md:text-4xl">
            <h2 className="flex flex-col pr-3.5 pl-3.5 w-full max-w-[1167px] min-h-[95px] max-md:max-w-full max-md:text-4xl">
              <div className="w-full max-md:max-w-full max-md:text-4xl">
                {" "}
                Industry segments{" "}
              </div>
            </h2>
            <div className="flex w-full max-w-[1167px] min-h-[1px] max-md:max-w-full" />
          </section>
          <div className="flex flex-wrap gap-10 justify-center w-full">
          {industrySegments.map((segment, index) => (
            <IndustrySegment key={index} {...segment} />
          ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default YourNeeds;
