import React from "react";

const Header: React.FC = () => {
  return (
    <header className="flex flex-col top-0 right-0 z-0 flex-wrap items-center p-6 h-24 bg-white gap-[837.5px] min-h-[96px] max-md:px-5">
      <div className="flex flex-col items-start self-stretch my-auto min-w-[320px]">
          <div className="flex items-center px-4 pb-px h-12 rounded-sm border border-solid border-neutral-400 min-w-[240px]">
            <label htmlFor="search" className="sr-only">
              Search or Ask a Question
            </label>
            <input
              type="text"
              id="search"
              className="self-stretch my-auto text-base leading-10 text-neutral-600"
              placeholder="Search or Ask a Question"
            />
            <div className="flex flex-col flex-1 shrink self-stretch pl-20 my-auto basis-0 min-w-[16px]">
              <div className="flex flex-col w-full">
                <div className="flex justify-center items-center w-full">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7654ed273316861d97cb5665471f68a3c680eb6e60ac87ea8541ed006e32a63c?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                    className="object-contain flex-1 shrink self-stretch my-auto w-4 aspect-square basis-0"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="flex flex-col grow shrink self-stretch pr-8 my-auto min-w-[240px] w-[323px]">
        <div className="flex gap-2 items-center w-full">
          <div className="flex flex-col items-start self-stretch pr-6 my-auto">
            <div className="flex items-center">
              <div className="flex justify-center items-center self-stretch my-auto w-6 min-h-[24px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e716ca5b86fa31220e6762c2ef67feabf62b604ca545163e5ad1b22454f77cf?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                  className="object-contain flex-1 shrink self-stretch my-auto w-6 aspect-square basis-0"
                  alt=""
                />
              </div>
            </div>
          </div>
          <button className="flex flex-col items-start self-stretch pr-2 my-auto text-base font-semibold leading-6 text-center text-neutral-900">
            <div className="flex justify-center items-center px-4 py-px rounded-sm border border-solid border-neutral-900">
              <div className="flex justify-center items-center self-stretch py-3 my-auto min-h-[46px]">
                <div className="self-stretch my-auto">Log in</div>
              </div>
            </div>
          </button>
          <a
            href="https://www.fintechain.com/signup"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col self-stretch my-auto text-base font-semibold leading-6 text-center text-white"
          >
            <div className="flex justify-center items-center px-4 py-px rounded-sm bg-neutral-900">
              <div className="flex justify-center items-center self-stretch py-3 my-auto min-h-[46px]">
                <div className="self-stretch my-auto">Get API Keys</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
