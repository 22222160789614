/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface SidebarItemProps {
  icon: string;
  text: string;
  isActive?: boolean;
  link?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  text,
  isActive,
  link,
}) => {
  const itemClass = isActive ? "bg-cyan-400" : "";
  const textClass = isActive ? "font-bold" : "font-semibold";

  const content = (
    <>
      <div className="flex flex-col items-start pr-2 my-auto min-h-[24px]">
        <div className="flex justify-center items-center w-6 min-h-[24px]">
          <img
            loading="lazy"
            src={icon}
            className="object-contain flex-1 shrink self-stretch my-auto w-full aspect-square basis-0"
            alt=""
          />
        </div>
      </div>
      <div className={`text-base ${textClass} leading-8 text-neutral-900`}>
        {text}
      </div>
    </>
  );

  return (
    <li className={`flex pr-8 pl-6 w-full ${itemClass} max-md:px-5`}>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center w-full"
        >
          {content}
        </a>
      ) : (
        content
      )}
    </li>
  );
};

export default SidebarItem;
