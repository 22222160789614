/**
 * This code was generated by Builder.io.
 */
import React from "react";

const PartnersSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center self-stretch px-20 pt-28 pb-16 bg-stone-50 max-md:px-5 max-md:pt-24 max-md:mr-0.5 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[1264px]">
        <div className="flex flex-wrap gap-5 justify-between items-start self-center max-w-full w-[1138px]">
          <div
            className=" text-5xl font-bold justify-start text-start object-contain max-w-full aspect-[3.46]"
          >
            Our solutions
          </div>
          <div className="flex flex-wrap gap-4 mt-4 min-h-[39px] max-md:max-w-full">
            <div className="flex flex-col grow shrink items-center pb-5 w-[115px]">
              <div
                className=" text-[9px] font-bold justify-start text-start object-contain max-w-full"
              >
                PAYMENT PROCESSING
              </div>
            </div>
            <div className="flex flex-col grow shrink items-center pb-5 w-[99px]">
              <div
                className=" text-[9px] font-bold justify-start text-start object-contain max-w-full"
              >
                RISK & COMPLIANCE
              </div>
            </div>
            <div className="flex flex-col grow shrink items-center pb-5 w-[82px]">
              <div
                className=" text-[9px] font-bold justify-start text-start object-contain max-w-full"
              >
                DIGITAL BANKING
              </div>
            </div>
            <div className="flex flex-col grow shrink items-center pb-5 w-[147px]">
              <div
                className=" text-[9px] font-bold justify-start text-start object-contain max-w-full"
              >
                TRANSACTION MANAGEMENT
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
