/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Link } from "react-router-dom";
interface NewsItem {
  date: string;
  readTime: string;
  title: string;
  excerpt: string;
  imageSrc: string;
}
const NewsCard: React.FC<NewsItem> = ({
  date,
  readTime,
  title,
  excerpt,
  imageSrc,
}) => (
  <article className="flex flex-col w-full">
    <img
      loading="lazy"
      src={imageSrc}
      alt={title}
      className="object-contain w-full aspect-[1.72]"
    />
    <time className="mt-6 text-xs text-black">
      {date} | {readTime} read
    </time>
    <h3 className="mt-4 text-2xl text-black">{title}</h3>
    <p className="mt-4 text-base text-neutral-500">{excerpt}</p>
    <Link
      to="#"
      className="flex gap-2 items-center mt-4 text-sm text-neutral-700"
    >
      Read more
      <span className="flex flex-col self-stretch my-auto min-h-[11px] w-[7px]">
        <svg className="object-contain w-full aspect-[0.64]" xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
          <g clip-path="url(#clip0_273_264)">
            <path d="M1.68818 11.9373C1.58818 11.9373 1.38818 11.8373 1.28818 11.7373C1.08818 11.5373 1.08818 11.1373 1.28818 10.9373L5.78818 6.4373L1.28818 1.9373C1.08818 1.7373 1.08818 1.3373 1.28818 1.1373C1.58818 0.937305 1.88818 0.937305 2.08818 1.1373L6.98818 6.0373C7.18818 6.2373 7.18818 6.6373 6.98818 6.8373L2.08818 11.7373C1.98818 11.8373 1.88818 11.9373 1.68818 11.9373Z" fill="#333D3E" />
          </g>
          <defs>
            <clipPath id="clip0_273_264">
              <rect width="7" height="11" fill="white" transform="translate(0.188232 0.9375)" />
            </clipPath>
          </defs>
        </svg>

      </span>
    </Link>
  </article>
);

const NewsSection: React.FC = () => {
  const newsItems: NewsItem[] = [
    {
      date: "27th Oct, 2024",
      readTime: "3 minutes",
      title: "Our sessions at Money20/20 in Las Vegas",
      excerpt: "Looking forward to our meeting at Money20/20 in Las Vegas...",
      imageSrc:
        "read1p.png",
    },
    {
      date: "21th Oct, 2024",
      readTime: "3 minutes",
      title: "Cibos: global financial community",
      excerpt: "Meeting our follows in SIBOs...",
      imageSrc:
        "read2p.png",
    },
    {
      date: "10th September 2024",
      readTime: "3 minutes",
      title: "Meeting our follows in SIBOs",
      excerpt: "",
      imageSrc:
        "read3p.png",
    },
  ];

  return (
    <section className="mt-36 mx-auto max-w-full w-[1109px] max-md:mt-10">
      <div className="flex justify-between items-start mb-7">
        <div
          className="object-contain font-bold text-3xl shrink-0 max-w-full aspect-[7.14] w-[215px]"
        >
          News & events
        </div>
        <Link to="/philosophy">
        <button className="self-stretch px-5 py-4 mt-1.5 border border-cyan-400 border-solid bg-cyan-400 bg-opacity-40 min-h-[55px] rounded-[50px] max-md:px-5" >
          See more news & events
        </button>
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {newsItems.map((item, index) => (
          <NewsCard key={index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default NewsSection;
