/**
 * This code was generated by Builder.io.
 */
import React from "react";

const FeatureSection: React.FC = () => {
  return (
    <section className="flex flex-col justify-center items-center self-stretch p-16 bg-cyan-400 bg-opacity-40 max-md:px-5 max-md:max-w-full">
      <div className="max-w-full w-[1093px]">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col pr-6 w-full min-h-[237px] max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col pb-px w-full max-md:max-w-full">
                <div
                  className=" text-3xl justify-start text-start object-contain max-w-full aspect-[3.46]"
                >
                  Focus on payment processing solution for banks and financial institutions
                </div>
              </div>
              <div className="flex gap-2 items-center mt-10 w-full rounded-[50px] max-md:max-w-full">
                <p className="self-stretch my-auto text-sm leading-tight text-neutral-700">
                  Learn more about what this means for you
                </p>
                <div className="flex flex-col self-stretch my-auto min-h-[11px] w-[7px]">
                  <div className="flex overflow-hidden flex-col justify-center items-center min-h-[11px] w-[7px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                      <g clip-path="url(#clip0_273_1132)">
                        <path d="M1.49995 11.2767C1.39995 11.2767 1.19995 11.1767 1.09995 11.0767C0.899951 10.8767 0.899951 10.4767 1.09995 10.2767L5.59995 5.77666L1.09995 1.27666C0.899951 1.07666 0.899951 0.67666 1.09995 0.47666C1.39995 0.27666 1.69995 0.27666 1.89995 0.47666L6.79995 5.37666C6.99995 5.57666 6.99995 5.97666 6.79995 6.17666L1.89995 11.0767C1.79995 11.1767 1.69995 11.2767 1.49995 11.2767Z" fill="#333D3E" />
                      </g>
                      <defs>
                        <clipPath id="clip0_273_1132">
                          <rect width="7" height="11" fill="white" transform="translate(0 0.276855)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow mt-2 max-md:mt-10 max-md:max-w-full">
              <div
                className=" text-xl font-bold justify-start text-start object-contain max-w-full aspect-[15.38]"
              >
                Latest updates in Fintech news
              </div>
              <div
                className=" text-base justify-start text-start object-contain px-2 mt-12 aspect-[12.05] w-[518px] max-md:mt-10 max-md:max-w-full"
              >
                Fintechain is actively collaborating with global banks and financial institutions to seamlessly embrace the upcoming transition from MT to lSO 20022.
              </div>
              <div
                className=" text-base justify-start text-start object-contain px-2 mt-12 aspect-[12.05] w-[518px] max-md:mt-10 max-md:max-w-full"
              >
                We are navigating transformative changes across five key areas: Core BankingSystems, Payment Processing Software, Risk and Compliance Tools, Digital Banking Platforms, and Treasury and Cash Management Solution</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
