/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Header from "../components/home/Header";
import HeroSection from "../components/home/HeroSection";
import FeatureSection from "../components/home/FeatureSection";
import PartnersSection from "../components/home/PartnersSection";
import TestimonialSection from "../components/home/TestimonialSection";
import CTASection from "../components/home/CTASection";
import ServiceCards from "../components/home/ServiceCards";
import NewsSection from "../components/home/NewsSection";
import NewsletterSection from "../components/home/NewsletterSection";
import Footer from "../components/foot/Footer";
import SocialPanl from "../components/home/SocialLink";
const Home: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="p-1.5 text-sm font-bold leading-loose text-white bg-cyan-400 bg-opacity-80 w-[164px] sm:w-[180px] lg:w-[200px]">
      Skip to main content
      </div>
      <Header />
      <main>
        <HeroSection />
        <FeatureSection />
        <PartnersSection />
        <TestimonialSection />
        <CTASection />
        <ServiceCards />
        <NewsletterSection />
        <NewsSection />
        <SocialPanl />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
