/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="flex flex-col items-center pr-3.5 min-h-[195px] max-md:mr-0.5 max-md:max-w-full">
      <div className="flex flex-col justify-center max-w-full min-h-[195px] w-[1170px]">
        <div className="flex flex-col flex-1 w-full max-w-[1167px] max-md:max-w-full">
          <nav className="flex flex-col justify-center pb-10 w-full whitespace-nowrap min-h-[83px] max-md:max-w-full">
            <div className="flex px-3.5 pt-5 w-full min-h-[43px] max-md:max-w-full">
              <div className="flex flex-col w-[106px]">
                <div className="flex w-full">
                  <div className="flex flex-col text-xs leading-relaxed text-slate-500">
                    <Link to="/" className="cursor-pointer">Home</Link>
                  </div>
                  <div className="flex overflow-hidden gap-2.5 px-3 h-full max-w-[111px] w-[79px]">
                    <div className="my-auto text-xs leading-5 text-neutral-700">
                      {" "}
                      ›{" "}
                    </div>
                    <div className="text-xs leading-relaxed text-slate-500" onClick={() => window.location.reload()} >
                      {" "}
                      Solutions{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className="z-10 mt-0 mr-0 w-full max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-[59%] max-md:ml-0 max-md:w-full">
                <h1 className="flex flex-col grow items-center px-3.5 mt-4 text-7xl leading-[77px] min-h-[112px] text-neutral-700 max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-col pb-9 max-w-full w-[654px]">
                    <div className="w-full max-md:max-w-full max-md:text-4xl">
                      {" "}
                      Fintechain Solutions{" "}
                    </div>
                  </div>
                </h1>
              </div>
              <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
                <div className="flex items-center py-8 pr-3.5 pl-3.5 w-full text-sm leading-6 text-center min-h-[112px] text-neutral-700 max-md:mt-10 max-md:max-w-full">
                  <button className="self-stretch px-8 py-3.5 my-auto bg-teal-200 border border-teal-200 border-solid min-h-[50px] rounded-[50px] max-md:px-5">
                    Receive updates from Fintechain
                  </button>
                  <div className="flex shrink-0 self-stretch my-auto h-[50px] w-[118px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
