/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface StatisticProps {
  percentage: string;
  description: string;
}

const Statistic: React.FC<StatisticProps> = ({ percentage, description }) => (
  <div className="flex flex-col flex-1 shrink gap-7 px-10 pt-20 pb-20 border-t border-solid basis-0 border-t-neutral-400 min-w-[240px] max-md:px-5 max-md:max-w-full">
    <div className="text-7xl font-semibold tracking-tighter leading-[80px] max-md:text-4xl">
      {percentage}
    </div>
    <div className="mt-7 text-2xl leading-8">{description}</div>
  </div>
);

const StatisticsSection: React.FC = () => {
  const statistics = [
    {
      percentage: "70%",
      description:
        "The decentralized clearing network reduces cyber attack risks for banking systems by 70%",
    },
    {
      percentage: "90%",
      description:
        "Fintechain reduces clearing and settlement costs for banks and financial institutions by 90%.",
    },
    {
      percentage: "1000%",
      description:
        "The blockchain engine enables transactions that are ten times more efficient than current systems.",
    },
  ];

  return (
    <section className="flex flex-col items-center px-24 mt-40 w-full bg-neutral-100 text-neutral-900 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-wrap px-44 pb-2 max-w-full w-[1730px] max-md:px-5">
        {statistics.map((stat, index) => (
          <Statistic key={index} {...stat} />
        ))}
      </div>
    </section>
  );
};

export default StatisticsSection;
