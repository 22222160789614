/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import MainContent from "./MainContent";
import Footer from "./Footer";

const Docs: React.FC = () => {
  return (
    <div className="flex overflow-x-auto  items-start">
      <Sidebar />
      <main className="flex flex-col grow shrink pt-24 min-w-[240px] max-md:max-w-full">
        <Header />
        <MainContent />
        <Footer />
      </main>
    </div>
  );
};

export default Docs;
