/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="flex z-0 flex-wrap gap-5 justify-between items-center py-3 pl-3.5 w-full bg-white max-w-[1100px] max-md:max-w-full">
      <nav className="flex flex-col self-stretch my-auto whitespace-nowrap min-h-[24px]">
        <div className="flex w-full">
          <div className="flex flex-col text-xs leading-relaxed text-slate-500">
            <Link to="/" className="cursor-pointer">Home</Link>
          </div>
          <div className="flex overflow-hidden gap-2.5 px-2.5 h-full text-xs leading-5 max-w-[111px] ">
            <div className="my-auto text-neutral-700">›</div>
            <div className="pl-0 text-slate-500" onClick={() => window.location.reload()} >Philosophy</div>
          </div>
        </div>
      </nav>
      <h2 className="self-stretch px-px my-auto text-3xl leading-none text-black uppercase whitespace-nowrap tracking-[2px]">
        Fintechain
      </h2>
      <div className="flex z-10 gap-1.5 self-stretch">
        <div className="flex justify-center items-center px-2.5 w-11 h-11 bg-white min-h-[44px]">
        <button className="self-start mt-2.5 text-base font-bold  text-black whitespace-nowrap bg-white bg-opacity-0 shadow-[0px_1px_0px_rgba(219,40,0,1)]">
          Subscribe
        </button>

        </div>
      </div>
    </header>
  );
};

export default Header;
