/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface IndustrySegmentProps {
  title: string;
  description: string;
  image: string;
}

const IndustrySegment: React.FC<IndustrySegmentProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <div className="flex relative items-start pt-10 pb-8 w-full leading-tight min-h-[520px] text-neutral-700 max-md:max-w-full">
      <img
        loading="lazy"
        src={image}
        alt={`${title} background`}
        className="object-contain absolute top-0 right-0 z-0 self-start aspect-[2.19] h-[520px] max-w-[1920px] min-w-[240px] w-[1140px] max-md:max-w-full"
      />
      <div className="flex relative z-0 flex-col items-center px-20 py-10 my-auto bg-white shadow-2xl min-w-[240px]  max-md:px-5 max-md:max-w-full">
        <h3 className="flex z-0 flex-col flex-1 items-center pb-4 max-w-full text-3xl whitespace-normal min-h-[36px] w-[280px]">
          <div className="w-full">{title}</div>
        </h3>
        <p className="flex z-0 flex-col max-w-full text-lg leading-7 w-[280px]">
          <div className="w-full">{description}</div>
        </p>
        <div className="flex z-0 flex-col items-center pt-6 pr-48 text-sm max-md:pr-5">
          <button className="flex items-center rounded-[50px]">
            <span className="self-stretch my-auto">Read more</span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/97a82f387f4f948bd39eab583100c3ba3ce067d1ef5b2be71f6a9473ba91c9e4?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
              alt=""
              className="object-contain  self-stretch my-auto aspect-[14.93] w-[15px]"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default IndustrySegment;
