import React from 'react';

const CompanyLinks: React.FC = () => {
  const links = ["About us", "Blog", "Contact us", "Pricing", "Testimonials"];

  return (
    <div className="flex flex-wrap grow shrink gap-2.5 justify-center items-start pt-9 text-xs min-h-[153px] text-stone-300 w-1/6">
      <h3 className="grow shrink text-base text-cyan-400 w-[170px]">Company</h3>
      {links.map((link, index) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a key={index} href="#" className="grow shrink leading-none w-[168px]">{link}</a>
      ))}
    </div>
  );
};

export default CompanyLinks;