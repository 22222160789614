/**
 * This code was generated by Builder.io.
 */
import React from 'react';

interface QuickLinkProps {
  icon: string;
  title: string;
  description: string;
  link: string;
  href?: string;
}

const QuickLink: React.FC<QuickLinkProps> = ({ icon, title, description, link, href }) => {
  return (
    <div className="flex flex-col grow shrink justify-center py-6 min-h-[268px] min-w-[250px] w-[303px]">
      <div className="flex flex-col justify-between p-6 w-full rounded bg-cyan-400 bg-opacity-40 min-h-[220px] min-w-[250px] shadow-[0px_8px_16px_rgba(18,18,18,0.08)] max-md:px-5">
        <div className="flex flex-col items-start pb-2 w-full">
          <div className="flex justify-center items-center w-6">
            <img loading="lazy" src={icon} className="object-contain flex-1 shrink self-stretch my-auto w-full aspect-square basis-0" alt={title} />
          </div>
        </div>
        <div className="w-full text-sm font-black leading-6 whitespace-nowrap text-neutral-900">{title}</div>
        <div className="flex flex-col pb-6 w-full text-sm leading-5 min-h-[64px] text-neutral-700">
          <div className="w-full min-h-[40px]">{description}</div>
        </div>
        <div className="flex flex-col flex-1 pt-7 w-full min-h-[24px]">
          <div className="flex items-center w-full">
            {href ? (
              <a href={href} target="_blank" rel="noopener noreferrer" className="self-stretch my-auto text-base font-semibold leading-6 text-neutral-900">
                {link}
              </a>
            ) : (
              <div className="self-stretch my-auto text-base font-semibold leading-6 text-neutral-900">{link}</div>
            )}
            <div className="flex justify-center items-center self-stretch my-auto w-6 min-h-[24px]">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9531875e642c08b55824ea4cac0f15c3c491bfc154a66e60514fea360248a2c?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31" className="object-contain flex-1 shrink self-stretch my-auto w-6 aspect-square basis-0" alt="Link icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLink;
