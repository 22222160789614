/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ArticleCard from "./ArticleCard";

const articles = [
  {
    category: "chains",
    date: "May 16, 2024",
    title: "Do Red Lobster Customers Care the Chain Appears Doomed?",
    subtitle: "We went to Times Square to find out.",
    author: "Zach Schiffman",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a1e6e5d6c44b61dcd9ccf4d8cad1ecefe981cdcc6ef501a1a60e5ffa02def55?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
  },
  {
    category: 'the business of brokering',
    date: 'Mar. 6, 2024',
    title: 'Among the Aspiring Serhants',
    subtitle: 'At an industry conference in midtown, brokers say they "wake up, bleed, sleep real estate — shit real estate."',
    author: 'Molly Osberg',
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/428cbf3b12d6224c91ce89de0969b191f3a658dc9c6aa03b8365ede70bbc11ab?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31',
    imageAlt: 'Real estate brokers at a conference'
  }
  // ... Add more article objects here
];

const ArticleList: React.FC = () => {
  return (
    <div className="flex flex-col max-w-full w-[761px]">
      {articles.map((article, index) => (
        <ArticleCard key={index} {...article} />
      ))}
    </div>
  );
};

export default ArticleList;
