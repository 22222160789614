import React from "react";

const CTASection: React.FC = () => {
  return (
    <section className="flex flex-wrap gap-10 items-center justify-center px-24 py-16 mt-11 max-w-full bg-cyan-400 bg-opacity-40 min-h-[243px] w-[1141px] mx-auto max-md:px-5 max-md:mt-10">
      <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-[62px] max-w-[760px] min-h-[103px] min-w-[240px] max-md:max-w-full">
        <div
          className="flex items-center text-4xl justify-start text-start max-w-full aspect-[14.49]"
        >
          Fintech aligns with customers' values
        </div>
        <div
          className="object-contain mt-5 max-w-full aspect-[13.51] w-[624px]"
        >
          The Fintechain portal empowers customers to utilize our products, manage payments efficiently, and access immediate technical support.
        </div>
      </div>
      <button className="flex gap-1 self-stretch px-8 py-5 my-auto bg-white min-h-[50px] rounded-[50px] w-[202px] max-md:px-5">
        <svg className="object-contain shrink-0 self-start mt-0.5 w-3.5 aspect-square" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
          <path d="M10.87 5.18729V3.08729L9.31446 1.53174H5.42558L3.87002 3.08729V5.10952H1.77002V13.6651H12.97V5.10952H10.87V5.18729ZM7.37002 10.4762C6.59224 10.4762 5.89224 9.85396 5.89224 8.9984C5.89224 8.22063 6.59224 7.52063 7.37002 7.52063C8.1478 7.52063 8.8478 8.14285 8.8478 8.9984C8.8478 9.85396 8.1478 10.4762 7.37002 10.4762ZM9.93669 5.18729H4.80335V3.47618L5.81446 2.46507H8.92558L9.93669 3.47618V5.18729Z" fill="#333D3E" />
        </svg>

        <span className="text-sm text-center text-neutral-700">
          Access <span className="">Fintechain</span>
        </span>
        <div className="flex flex-col mt-1.5 my-auto min-h-[11px]">
          <div className="flex overflow-hidden flex-col justify-center items-center min-h-[11px] w-[7px]">
            <svg className="object-contain w-full aspect-[0.64]" xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <g clip-path="url(#clip0_273_1164)">
                <path d="M1.49019 11.116C1.39019 11.116 1.19019 11.016 1.09019 10.916C0.890186 10.716 0.890186 10.316 1.09019 10.116L5.59019 5.61602L1.09019 1.11602C0.890186 0.916016 0.890186 0.516016 1.09019 0.316016C1.39019 0.116016 1.69019 0.116016 1.89019 0.316016L6.79019 5.21602C6.99019 5.41602 6.99019 5.81602 6.79019 6.01602L1.89019 10.916C1.79019 11.016 1.69019 11.116 1.49019 11.116Z" fill="#333D3E" />
              </g>
              <defs>
                <clipPath id="clip0_273_1164">
                  <rect width="7" height="11" fill="white" transform="translate(-0.00976562 0.116211)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </button>
    </section>
  );
};

export default CTASection;
