import React from 'react';

const SupportLinks: React.FC = () => {
  const links = ["Help center", "Terms of service", "Legal", "Privacy policy", "Status"];

  return (
    <div className="flex flex-wrap grow shrink gap-2.5 justify-center items-start pt-9 text-xs min-h-[153px] text-stone-300 w-1/6">
      <h3 className="grow shrink text-base text-cyan-400 w-[180px]">Support</h3>
      {links.map((link, index) => (
        <a key={index} href="#" className="grow shrink leading-none w-[180px]">{link}</a>
      ))}
    </div>
  );
};

export default SupportLinks;