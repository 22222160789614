/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="flex overflow-hidden overflow-x-auto flex-col items-start w-full min-h-[129px] text-neutral-900 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[1184px]">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="flex border-t border-solid border-t-gray-200 min-h-[1px] w-[1184px] max-md:max-w-full" />
          <div className="flex flex-wrap gap-10 justify-between items-start mt-8 w-full max-md:max-w-full">
            <div className="flex flex-col pb-10 max-w-[608px] min-w-[112px] w-[364px]">
              <div className="flex flex-col items-center pr-10 pb-2 w-full max-w-[364px] min-w-[112px] max-md:pr-5">
                <div className="flex w-full">
                  <div className="flex flex-col justify-center py-3 text-base leading-6">
                    <div className="min-h-[24px] min-w-[124px]">
                      Was this helpful?
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-start pl-6 text-sm font-semibold leading-4 text-center whitespace-nowrap">
                    <div className="flex items-start min-h-[48px]">
                      <button className="flex flex-col items-start pt-2 pr-4 min-h-[40px] w-[88px]">
                        <div className="flex justify-center items-center px-4 py-px w-full rounded-sm border border-solid border-neutral-900 max-w-[72px] min-h-[32px]">
                          <div className="flex flex-1 shrink justify-center items-center self-stretch py-2 pr-2 pl-2 my-auto w-full basis-0 min-h-[30px]">
                            <div className="flex items-center self-stretch my-auto">
                              <div className="self-stretch my-auto">Yes</div>
                            </div>
                          </div>
                        </div>
                      </button>
                      <button className="flex flex-col items-start pt-2 pr-4 min-h-[40px] w-[88px]">
                        <div className="flex justify-center items-center px-4 py-px w-full rounded-sm border border-solid border-neutral-900 max-w-[72px] min-h-[32px]">
                          <div className="flex flex-1 shrink justify-center items-center self-stretch p-2 my-auto w-full basis-0 min-h-[30px]">
                            <div className="flex items-center self-stretch my-auto">
                              <div className="self-stretch my-auto">No</div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-36 text-base leading-6 min-w-[112px]">
              <div className="self-start pb-2">Developer community</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f812e6241ba37f135b00367649220bc38f8a480606803a26652d84d41ef2181?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                className="object-contain w-36 max-w-full aspect-[4.5]"
                alt="Developer community logos"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
