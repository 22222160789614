/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const TestimonialSection: React.FC = () => {
  return (
    <section className="flex flex-wrap justify-center items-center mt-11 mx-auto w-full max-md:mt-10 max-md:max-w-full">
      <button
        aria-label="Previous testimonial"
        className="flex justify-center items-center self-stretch px-5 mt-52 mb-auto bg-cyan-400 bg-opacity-40 h-[60px] min-h-[60px] rounded-[30px] w-[60px]"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clip-path="url(#clip0_146_1910)">
            <path d="M1.88889 10.6667C2 10.7778 1.88889 10.7778 1.88889 10.6667V10.6667Z" fill="#333D3E" />
            <path d="M1.77783 10.6668H19.7778V9.22239H1.88894C1.55561 9.22239 1.4445 8.88905 1.66672 8.66683L8.66672 1.66683L7.66672 0.66683L0.333387 8.00016V12.0002L7.66672 19.3335L8.66672 18.3335L1.66672 11.3335C1.33339 11.1113 1.4445 10.6668 1.77783 10.6668Z" fill="#333D3E" />
          </g>
          <defs>
            <clipPath id="clip0_146_1910">
              <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 -1 20 20)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div className="flex overflow-hidden flex-wrap justify-center self-stretch text-sm leading-5 text-center min-h-[430px] text-neutral-700 max-md:max-w-full">
        <div className="flex flex-col grow shrink items-start pt-28 pr-20 pb-28 pl-24 bg-white max-w-[570px] min-w-[240px] w-[625px] max-md:px-5 max-md:py-24 max-md:max-w-full">
          <div
            className="text-[12px] justify-start text-start object-contain aspect-[1.02] max-w-full"
          >
            Discover
          </div>
          <div
            className="text-[50px] justify-start text-start object-contain  aspect-[2.02] max-w-full"
          >
            Banking
          </div>
          <div
            className="text-[18px] justify-start text-start object-contain  aspect-[3.02] max-w-full"
          >
            Our messaging system and blockchain servicesenable banks to transact more securely and atlower costs
          </div>

          <button className="px-8 pt-4 pb-4 mt-10 border border-cyan-400 border-solid bg-cyan-400 bg-opacity-40 min-h-[52px] rounded-[50px] max-md:px-5">
            Read more
          </button>
        </div>
        <img
          loading="lazy"
          src="./bank1.png"
          alt="Testimonial image"
          className="object-cover grow shrink self-start mx-auto aspect-[0.94] min-w-[240px] w-[542px] max-md:max-w-full"
        />
      </div>
      <button
        aria-label="Next testimonial"
        className="flex justify-center items-center self-stretch px-5 mt-52 mb-auto bg-cyan-400 bg-opacity-40 h-[60px] min-h-[60px] rounded-[30px] w-[60px]"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b9104decba55465f616c9f61fad31b7689b0d0c617a9dd7c55b9aac0b19d353?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
          alt=""
          className="object-contain self-stretch my-auto w-5 aspect-square"
        />
      </button>
    </section>
  );
};

export default TestimonialSection;
