import React from "react";
import ContactForm from "./ContactForm";
import FeatureList from "./FeatureList";

const Contact: React.FC = () => {
  return (
    <div className="flex relative flex-col bg-white">
      <header className="flex inset-x-0 -top-px z-0 items-center px-8 py-5 pt-5 pb-5 text-3xl font-bold leading-none whitespace-nowrap bg-white min-h-[85.5px] text-stone-900 w-[2000px] max-md:px-5 max-md:max-w-full">
        <div className="flex gap-1 self-stretch py-px my-auto w-[204px]">
          <a href="/" className="flex items-center">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6baab53ef4dc285dad3889a2ac7bd4f00714186a620fbdde9e3b5dc079dc79cc?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31" alt="" className="object-contain shrink-0 aspect-square w-[25px]" />
            <div className="grow shrink w-[170px]">Fintechain</div>
          </a>
        </div>
      </header>
      <main className="flex z-0 flex-col justify-center items-center px-16 py-64 w-full bg-cyan-400 bg-opacity-40 min-h-[1116px] max-md:px-5 max-md:py-24 max-md:max-w-full">
        <div className="mb-0 w-full max-w-[1560px] max-md:mb-2.5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <section className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow mt-11 text-base font-light leading-7 text-gray-800 max-md:mt-10 max-md:max-w-full">
                <h1 className="text-6xl font-black leading-[75px] text-blue-950 max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
                  Experience with Decentralized Payment Processing Services
                </h1>
                <p className="mt-6 mr-6 max-md:mr-2.5 max-md:max-w-full">
                  See how you can break free from legacy AR and payment infrastructure, and discover how <br /> you can:
                </p>
                <FeatureList />
                <p className="self-start mt-9 max-md:max-w-full">
                  Fill out the form to get started with your free demo of Paystand's AR automation and <br /> payment platform.
                </p>
              </div>
            </section>
            <section className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <ContactForm />
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;