import React from 'react';

const FooterLinks: React.FC = () => {
  const links = ["Privacy statement", "Terms of use", "Modern slavery statement"];

  return (
    <div className="flex flex-wrap gap-10 items-start px-0 py-8 pt-8 pr-3.5 pb-2.5 -mb-px w-full text-xs leading-relaxed border-t border-solid border-t-stone-700 text-gray-400 text-opacity-60 max-md:max-w-full">
      <div className="flex grow shrink gap-10 -mb-px min-w-[240px] w-[432px] max-md:max-w-full">
        {links.map((link, index) => (
          <a key={index} href="#" className="min-w-[88px]">{link}</a>
        ))}
      </div>
      <div className="flex grow  text-center rounded-none w-0">
        <span className="my-auto ml-auto w-full"></span>
        <span className="my-auto ml-auto w-full"></span>
        <span className="self-stretch mr-auto w-full h-auto">@ 2024 Fintechain</span>
      </div>
    </div>
  );
};

export default FooterLinks;