import React from 'react';

const EmailSubscription: React.FC = () => {
  return (
    <div className="flex flex-col grow shrink pt-9 pb-2.5 w-1/6">
      <h3 className="text-base text-cyan-400">Stay up to date</h3>
      <form className="flex gap-4 self-center px-0.5 py-px mt-4 text-xs leading-none text-gray-400 bg-stone-500 bg-opacity-20 w-3/5  rounded-full">
        <label htmlFor="emailInput" className="sr-only">Your email address</label>
        <input
          type="email"
          id="emailInput"
          placeholder="Your email address"
          className="bg-transparent border-none text-gray-400 text-xs w-full"
          aria-label="Your email address"
        />
        <button type="submit" aria-label="Submit email">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c50fcc7b913bfe1ad56e82b75db944343686eab52512192de6c40ea70672267?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31" className="object-contain shrink-0 aspect-square w-[15px]" alt="" />
        </button>
      </form>
    </div>
  );
};

export default EmailSubscription;