/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface SolutionCardProps {
  imageSrc: string;
  title: string;
  description: string;
}

const SolutionCard: React.FC<SolutionCardProps> = ({
  imageSrc,
  title,
  description,
}) => (
  <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow text-neutral-900 max-md:mt-10">
      <img
        loading="lazy"
        src={imageSrc}
        alt={title}
        className="object-contain w-full rounded-2xl aspect-[1.32]"
      />
      <h3 className="self-start mt-11 text-3xl font-semibold tracking-tighter leading-10 max-md:mt-10">
        {title}
      </h3>
      <p className="mt-5 text-lg leading-7 max-md:mr-2.5">{description}</p>
    </div>
  </div>
);

const SolutionsGrid: React.FC = () => {
  const solutions = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/78e33eedf151da821c8e3150cd47028f9e1cb43f0a8a7845b790a579d3227f57?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
      title: "Message standard address",
      description:
        "Fintechain establishes the Message Standard Address (MSA) as a decentralized identity address naming convention for banks and financial institutions",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/8cecd979af492a38574d34bacbf868e6ea97ecce931a71272095a24ae5fa6de3?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
      title: "Clearing processing",
      description:
        "Fintechain identifies a four-step processing mechanism for decentralized clearing systems",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/2c40126ad36153c94e4ccdeb280b697364d74e8939e35a407d59d015da2dad66?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
      title: "Blockchain engine",
      description:
        "Fintechain leverages a blockchain engine as the backend infrastructure to ensure the security of the clearing system",
    },
  ];

  return (
    <section className="flex flex-col gap-0 items-center mt-32 w-full bg-neutral-100 min-h-[577px] max-md:mt-10 max-md:max-w-full">
      <div className="pb-5 max-w-full w-[1730px]">
        <div className="flex gap-5 max-md:flex-col">
          {solutions.map((solution, index) => (
            <SolutionCard key={index} {...solution} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolutionsGrid;
