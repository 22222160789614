import React from "react";

const features = [
  "Accelerate your time-to-cash cycle and reduce days sales outstanding by 62%.",
  "Automate 70% of your invoicing and AR tasks.",
  "Eliminate 51% of your payment processing costs with flexible, digital payment options for your customers.",
];

const FeatureList: React.FC = () => {
  return (
    <ul className="flex flex-col pl-8 mt-9 leading-loose max-md:pl-5 max-md:max-w-full">
      {features.map((feature, index) => (
        <li key={index} className={index !== 0 ? "mt-4" : ""}>
          {feature}
        </li>
      ))}
    </ul>
  );
};

export default FeatureList;