/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const HeroSection: React.FC = () => {
  return (
    <section className="self-stretch max-md:max-w-full">
      <div className="flex  max-md:flex-col">
        <div className="mx-auto flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col px-20 py-20 w-full text-sm leading-5 text-center bg-neutral-700 text-neutral-700 max-md:px-5 max-md:max-w-full">
            <div
              className="ml-[34%] font-bold items-start text-left object-contain text-5xl aspect-[2.65] text-white max-w-full "
            >
              Global Payment Infrastructure: Clearing Network
            </div>
            <div
              className="ml-[34%] items-start text-left object-contain text-lg text-white max-w-full"
            >
              Fintechain leverages the clearing network as an entry point to address challenges within the global financial system
            </div>
            <button className="self-center px-8 pt-4 pb-4 mt-8 bg-cyan-400 border border-teal-200 border-solid min-h-[52px] rounded-[50px] max-md:px-5">
              Read more
            </button>
          </div>
        </div>
        <div className="mx-auto flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex overflow-hidden relative flex-col grow min-h-[500px] max-md:max-w-full">
            <img
              loading="lazy"
              src="./WebsiteFirstPic.png"
              alt="Background image"
              className="object-cover absolute inset-0 size-full"
            />
            <div className="flex relative flex-col items-center px-16 pt-96 pb-5 w-full max-md:px-5 max-md:pt-24 max-md:max-w-full">
              <div className="flex gap-8 items-start py-2 pr-8 pl-9 ml-7 max-w-full w-[155px] max-md:px-5">
                <svg className="object-contain shrink-0 w-2.5 aspect-square" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 10" fill="none">
                  <path d="M0 5.41016C0 2.64873 2.23858 0.410156 5 0.410156C7.76142 0.410156 10 2.64873 10 5.41016C10 8.17158 7.76142 10.4102 5 10.4102C2.23858 10.4102 0 8.17158 0 5.41016Z" fill="#ACF9E9" />
                </svg>

                <svg className="object-contain shrink-0 w-2.5 aspect-square" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 10" fill="none">
                  <path d="M0 5.41016C0 2.64873 2.23858 0.410156 5 0.410156C7.76142 0.410156 10 2.64873 10 5.41016C10 8.17158 7.76142 10.4102 5 10.4102C2.23858 10.4102 0 8.17158 0 5.41016Z" fill="white" />
                </svg>
                <svg className="object-contain shrink-0 w-2.5 aspect-square" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 10" fill="none">
                  <path d="M0 5.41016C0 2.64873 2.23858 0.410156 5 0.410156C7.76142 0.410156 10 2.64873 10 5.41016C10 8.17158 7.76142 10.4102 5 10.4102C2.23858 10.4102 0 8.17158 0 5.41016Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
