/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface ArticleCardProps {
  category: string;
  date: string;
  title: string;
  subtitle: string;
  author: string;
  imageSrc: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  category,
  date,
  title,
  subtitle,
  author,
  imageSrc,
}) => {
  return (
    <article className="flex flex-col px-12 w-full max-md:px-5 max-md:max-w-full">
      <div className="px-4 pb-8 w-full border-b border-solid border-b-zinc-300 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[61%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start mt-5 w-full leading-6 max-md:mt-10">
              <div className="flex gap-2.5 leading-4 text-black uppercase">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d65d85c4b35fff8e37567652857437b151cc541fbb09789241993e4c2baa6465?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                  className="object-contain shrink-0 w-4 aspect-square"
                  alt=""
                />
                <div className="my-auto text-xs tracking-widest">
                  {category}
                </div>
                <div className="flex shrink-0 my-auto w-px bg-[linear-gradient(180deg,#FFF_7.69%,#DB2800_15.38%,#DB2800_84.62%,#FFF_100%)] h-[13px]" />
                <div className="text-xs tracking-wider">{date}</div>
              </div>
              <h3 className="flex self-stretch mt-1.5">
                <div className="grow py-px mr-0 text-lg font-bold text-black w-fit">
                  {title}
                </div>
              </h3>
              <p className="text-xl text-neutral-500">{subtitle}</p>
              <p className="mt-2 text-sm italic tracking-wider leading-5 text-black">
                By {author}
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[39%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={imageSrc}
              className="object-contain grow w-full aspect-[1.5] max-md:mt-10"
              alt={title}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleCard;
