/**
 * This code was generated by Builder.io.
 */
import React from "react";

const LoadMoreButton: React.FC = () => {
  return (
    <button className="self-center py-4 pr-16 pl-16 mt-10 w-60 max-w-full text-sm leading-none text-center uppercase rounded-sm border border-solid border-zinc-600 text-zinc-600 tracking-[2px] max-md:px-5">
      Load More
    </button>
  );
};

export default LoadMoreButton;
