import React from 'react';

const UnderConstruction: React.FC = () => {
  return (
    <div className="relative flex flex-col items-center justify-center h-screen text-center bg-gray-100">
      <img
        src="/fix.webp"
        alt="Under Construction"
        className="absolute inset-0 object-cover w-full h-full z-0" // Fullscreen image
      />
      <div className="relative z-10 bg-white bg-opacity-75 p-6 rounded shadow-lg"> 
        <h1 className="text-4xl font-bold mb-4">Page Under Construction</h1>
        <p className="text-lg text-gray-700 mb-6">We are working hard to improve this page, please check back later.</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
