import React from 'react';

const ContactInfo: React.FC = () => {
  return (
    <div className="pt-4 pb-8 -mb-px w-full border-t border-stone-700 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[36%] max-md:ml-0 max-md:w-full">
          <div className="flex gap-6 text-white max-md:mt-10">
            <div className="flex flex-col items-start self-start">
              <h4 className="text-xs">Headquarters</h4>
              <p className="mt-6 ml-5 text-xs max-md:ml-2.5">20725 valley green drive, Cupertino, CA</p>
            </div>
            <div className="flex flex-col">
              <h4 className="self-start text-xs">Branch</h4>
              <p className="mt-5 ml-3.5 text-xs max-md:ml-2.5">
                160 E Tasman Dr, San Jose, CA<br />
                350 W Ontario St, Chicago IL 60654
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-wrap gap-4 justify-between items-start w-full max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col items-start text-white">
              <h4 className="text-xs">Email</h4>
              <p className="mt-5 ml-4 text-xs max-md:ml-2.5">
                contact@fintechain.com<br />
                info@fintechain.org
              </p>
            </div>
            <button className="flex gap-2 justify-center items-center px-2.5 py-1 mt-4  border border-solid border-cyan-400 border-opacity-40 min-h-[50px] rounded-full">
              <span className="self-stretch my-auto text-sm text-center text-white">Get in touch</span>
              <span className="flex flex-col self-stretch my-auto min-h-[11px] w-[7px]">
                <span className="flex overflow-hidden flex-col justify-center items-center min-h-[11px] w-[7px]">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3e20ee2991ffa4730376ed12e78c5762c72069049ea0766fc34177a82d3746c?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31" className="object-contain w-full aspect-[0.64]" alt="" />
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;