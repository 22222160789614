/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import PartnerLogos from "./PartnerLogos";
import SolutionsGrid from "./SolutionsGrid";
import StatisticsSection from "./StatisticsSection";
import NetworkSection from "./NetworkSection";
import ContactSection from "./ContactSection";

const FinteChainSolutions: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col bg-neutral-100">
      <Header />
      <HeroSection />
      <PartnerLogos />
      <SolutionsGrid />
      <StatisticsSection />
      <NetworkSection />
      <ContactSection />
      <footer className="flex w-full bg-neutral-900 min-h-[100px] max-md:max-w-full" />
      <div className="flex w-full bg-cyan-400 bg-opacity-40 min-h-[100px] max-md:max-w-full" />
    </div>
  );
};

export default FinteChainSolutions;
