/**
 * This code was generated by Builder.io.
 */
import React from "react";

const ContactSection: React.FC = () => {
  return (
    <section className="flex relative px-32 mt-36 w-full bg-neutral-900 min-h-[865px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex z-0 flex-col flex-1 shrink justify-center px-36 py-72 mt-20 text-6xl font-semibold tracking-tighter bg-cyan-100 rounded-lg basis-0 leading-[66px] min-w-[240px] text-neutral-900 max-md:px-5 max-md:py-24 max-md:max-w-full max-md:text-4xl">
        <h2 className="w-full max-md:max-w-full max-md:text-4xl max-md:leading-[52px]">
          Speak with an expert <br /> to find out how Plaid <br /> can help you
          grow <br /> your business.
        </h2>
      </div>
      <div className="flex z-0 flex-col flex-1 shrink gap-10 px-36 py-36 mt-20 bg-white rounded-none basis-0 min-w-[240px] max-md:px-5 max-md:py-24 max-md:max-w-full">
        <h3 className="text-3xl font-semibold tracking-tighter leading-10 text-neutral-900">
          Ready to get started?
        </h3>
        <form className="flex flex-col pb-4 mt-10 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-4 text-base leading-6 text-neutral-600">
            <div className="flex relative flex-col flex-1 grow shrink-0 basis-0 min-h-[40px] w-fit">
              <input
                className="flex z-0 w-full bg-white rounded-lg border border-solid border-neutral-400 min-h-[48px] p-2"
                type="text"
                id="firstName"
                aria-label="First name"
              />
              <label
                htmlFor="firstName"
                className="absolute bottom-3 left-4 right-44 w-[77px]"
              >
                First name
              </label>
            </div>
            <div className="flex relative flex-col flex-1 grow shrink-0 basis-0 min-h-[40px] w-fit">
              <input
                className="flex z-0 w-full bg-white rounded-lg border border-solid border-neutral-400 min-h-[48px] p-2"
                type="text"
                id="lastName"
                aria-label="Last name"
              />
              <label
                htmlFor="lastName"
                className="absolute bottom-3 left-4 right-[178px] w-[76px]"
              >
                Last name
              </label>
            </div>
          </div>
          <div className="flex relative flex-col mt-6 text-base leading-6 min-h-[40px] text-neutral-600 max-md:max-w-full">
            <input
              className="flex z-0 w-full bg-white rounded-lg border border-solid border-neutral-400 min-h-[48px] p-2"
              type="email"
              id="companyEmail"
              aria-label="Company email"
            />
            <label
              htmlFor="companyEmail"
              className="absolute bottom-3 left-4 max-w-full right-[426px] w-[113px]"
            >
              Company email
            </label>
          </div>
          <div className="flex relative flex-col mt-6 text-base leading-6 min-h-[40px] text-neutral-600 max-md:max-w-full">
            <input
              className="flex z-0 w-full bg-white rounded-lg border border-solid border-neutral-400 min-h-[48px] p-2"
              type="text"
              id="companyName"
              aria-label="Company name"
            />
            <label
              htmlFor="companyName"
              className="absolute bottom-3 left-4 max-w-full right-[425px] w-[114px]"
            >
              Company name
            </label>
          </div>
          <div className="flex relative flex-col mt-6 text-base leading-6 min-h-[40px] text-neutral-600 max-md:max-w-full">
            <input
              className="flex z-0 w-full bg-white rounded-lg border border-solid border-neutral-400 min-h-[48px] p-2"
              type="tel"
              id="phoneNumber"
              aria-label="Phone number (optional)"
            />
            <label
              htmlFor="phoneNumber"
              className="absolute bottom-3 left-4 max-w-full right-[360px] w-[179px]"
            >
              Phone number (optional)
            </label>
          </div>
          <div className="flex relative flex-col mt-6 w-full bg-white rounded-lg min-h-[48px] max-md:max-w-full">
            <label
              htmlFor="country"
              className="absolute bottom-3 left-4 text-base leading-6 whitespace-nowrap right-[482px] text-neutral-600 w-[57px]"
            >
              Country
            </label>
            <select
              id="country"
              className="flex z-0 flex-wrap justify-between content-center items-center px-px py-1 w-full rounded-lg border border-solid border-neutral-500 min-h-[48px] max-md:max-w-full appearance-none bg-white p-2"
              aria-label="Select country"
            >
              <option value="">Select a country</option>
            </select>
            <div className="flex justify-center items-center self-stretch py-2 pr-2 my-auto w-8 absolute right-0 top-0 bottom-0 pointer-events-none">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/105a28b347dede088ad99b115bc98e59447008c2521182eebede026515ce9147?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                alt=""
                className="object-contain self-stretch my-auto w-6 aspect-square"
              />
            </div>
          </div>
          <button
            type="submit"
            className="flex flex-wrap gap-3 justify-center items-center px-52 py-3.5 mt-4 text-xl font-semibold tracking-tighter leading-8 text-center text-white border border-solid bg-neutral-900 border-neutral-900 min-h-[56px] rounded-[240px] max-md:px-5"
          >
            <span className="self-stretch my-auto">Contact Sales</span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6686368488fde380dc20c5aa1504eb5c6dd16dcc693f859e8c53e56fe6b8ffe6?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
              alt=""
              className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            />
          </button>
          <p className="self-start mt-10 text-base leading-6 text-neutral-700">
            By submitting this form, I confirm that I have read and understood{" "}
            <br /> Plaid's{" "}
            <a href="#" className="underline text-neutral-900">
              Privacy Statement
            </a>
            .
          </p>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
