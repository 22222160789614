/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Header from "./Header";
import ArticleList from "./ArticleList";
import LoadMoreButton from "./LoadMoreButton";

const Philosophy: React.FC = () => {
  return (
    <div className="flex flex-col items-center min-h-[1200px]">
      <div className="flex relative flex-col max-w-full w-[1100px]">
        <div className="flex absolute -bottom-px z-0 h-px bg-stone-300 min-h-[1px] right-[-410px] w-[1920px] max-md:max-w-full" />
        <Header />
        <div className="flex self-stretch w-full bg-cyan-400 bg-opacity-40 min-h-[82px] max-md:max-w-full" />
        <h1 className="px-16 pb-1 mt-16 max-w-full text-sm uppercase text-black text-opacity-90 tracking-[2.5px] w-[761px] max-md:px-5 max-md:mt-10">
          <span className="text-black">Displaying all articles tagged:</span>
          <br />
          <span className="text-4xl leading-10 text-black capitalize">
            Times Square
          </span>
        </h1>
        <ArticleList />
        <LoadMoreButton />
      </div>
    </div>
  );
};

export default Philosophy;
