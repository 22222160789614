/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface MarketSegmentProps {
  title: string;
  description: string;
  icon: string;
}

const MarketSegment: React.FC<MarketSegmentProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center px-3.5 pb-12 min-h-[528px]">
        <div className="flex relative flex-col gap-0 w-full bg-stone-50 h-[478px]">
          <div className="flex z-0 flex-col flex-1 px-8 py-9 w-full max-md:px-5">
            <img
              loading="lazy"
              src={icon}
              alt={`${title} icon`}
              className="object-contain max-w-full aspect-[1.11] w-[119px]"
            />
            <h3 className="pb-px mt-8 text-lg leading-none whitespace-nowrap text-neutral-700">
              {title}
            </h3>
            <p className="pb-px mt-3.5 text-xs leading-6 text-slate-500">
              {description}
            </p>
          </div>
          <div className="flex  z-0 px-8 pb-6 w-full text-sm leading-tight text-neutral-700 max-md:px-5">
            <button className="flex items-center h-full rounded-[50px]">
              <span className="self-stretch my-auto">Read more</span>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ed31601e5e26e408a247277455ca10767c2e7b885960d0f743cad4c2029c2729?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                alt=""
                className="object-contain shrink-0 self-stretch my-auto aspect-[14.93] w-[15px]"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketSegment;
