/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ProductCard from "./ProductCard";

interface ProductSectionProps {
  title: string;
}

const ProductSection: React.FC<ProductSectionProps> = ({ title }) => {
  return (
    <section className="mt-16 max-md:max-w-full">
      <h2 className="flex justify-center items-start text-2xl leading-8 text-neutral-900 max-md:mt-10">
        <div className="flex-1 shrink w-full min-w-[240px] max-md:max-w-full">
          {title}
        </div>
      </h2>
      <div className="flex flex-wrap gap-5 mt-10 max-md:flex-col">
        <ProductCard
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/ea50cb7b4b39bdeb115499221ff0c01ea6879f7bf8ff1feaa4645415146829c0?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
          title="Auth"
          description="Retrieve bank account information for ACH, wire, and other bank-to-bank transfers."
        />
        <ProductCard
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/b51caa3ab19616dc599a06440c48c1fbdb92b242543af5d0860037c8b7876522?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
          title="Balance"
          description="Retrieve real-time balance information to prevent ACH returns."
        />
        <ProductCard
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/4a34f0ea5549fef02ba28869b5a550f7c5b274187908e0976179583f05f29ece?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
          title="Identity"
          description="Verify users' financial account ownership and reduce fraud."
        />
      </div>
    </section>
  );
};

export default ProductSection;
