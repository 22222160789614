/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import Home from "./pages/Home";
import Needs from "./components/need/YourNeeds"
import FinteChainSolutions from "./components/solutions/FinteChainSolutions";
import Docs from "./components/docs/Docs";
import Philosophy from "./components/Philosophy/Philosophy";
import Contact from "./components/contact/Contact";
import UnderConstruction from "./components/error/UnderConstruction";

const App: React.FC = () => {
  return (
    <Router>
      <Routes >
        <Route path="/" element={< Home />} />
        <Route path="/needs" element={< Needs />} />
        <Route path="/solutions" element={< FinteChainSolutions/>} />
        <Route path="/docs" element={< Docs/>} />
        <Route path="/philosophy" element={< Philosophy />} />
        <Route path="/contact" element={< Contact />} />
        <Route path="/*" element={< UnderConstruction />} />
      </Routes >
    </Router>
  );
};

export default App;
