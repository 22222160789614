/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Welcome from "./Welcome";
import ProductSection from "./ProductSection";

const MainContent: React.FC = () => {
  return (
    <section className="flex relative flex-col pb-20 w-full max-w-[1600px] min-h-[3314px] max-md:max-w-full">
      <div className="flex z-0 flex-col justify-center pl-48 w-full min-h-[3138px] max-md:pl-5 max-md:max-w-full">
        <div className="flex flex-col items-start w-full min-h-[2777px] max-md:max-w-full">
          <Welcome />
          <ProductSection title="Payments and Funding" />
          <ProductSection title="Financial Insights" />
          <ProductSection title="KYC and AML Compliance" />
          <ProductSection title="Instant Onboarding" />
          <ProductSection title="Credit and Underwriting" />
        </div>
      </div>
    </section>
  );
};

export default MainContent;
