/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const ContactForm: React.FC = () => {
  return (
    <div className="flex z-0 flex-col flex-1 shrink px-3.5 basis-0 max-w-[584px] min-w-[240px] max-md:max-w-full">
      <div className="flex flex-col items-start w-full text-3xl text-white max-md:max-w-full">
        <div className="z-10 pb-0 max-w-full rounded-none w-[538px] max-md:pr-5 max-md:pb-2.5 max-md:max-w-full">
          Receive industry-leading information and insights directly in your inbox
        </div>
      </div>
      <button className="w-1/4 px-4 py-2 mt-10 text-sm leading-relaxed text-center border border-cyan-400 border-solid bg-cyan-400 bg-opacity-40 min-h-[40px] rounded-[50px] text-neutral-200 max-md:px-5" >
        Sign up now
      </button>
    </div>
  );
};

const ContactImage: React.FC = () => {
  return (
    <img
      loading="lazy"
      src="./bgmess.png"
      className="object-contain absolute bottom-0 left-0 z-0 self-start aspect-[6.58] h-[292px] max-w-[1920px] min-w-[240px] w-[1920px] max-md:max-w-full"
      alt="Background image"
    />
  );
};

const NewsletterSection: React.FC = () => {
  return (
    <div className="flex relative justify-center self-stretch px-96 py-16 mt-20 bg-cyan-400 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <ContactImage />
      <ContactForm />
      <svg className="object-contain z-0 flex-1 shrink my-auto aspect-[7.04] basis-[26px] max-w-[584px] min-w-[240px] w-[584px] max-md:max-w-full" xmlns="http://www.w3.org/2000/svg" width="584" height="84" viewBox="0 0 584 84" fill="none">
        <path d="M210.557 21.2998V19.9912C210.296 20.4925 209.961 20.8766 209.551 21.1436C209.141 21.404 208.698 21.5342 208.223 21.5342C207.22 21.5342 206.351 21.0622 205.615 20.1182C204.886 19.1676 204.521 17.833 204.521 16.1143C204.521 14.9359 204.684 13.9365 205.01 13.1162C205.335 12.2959 205.768 11.6872 206.309 11.29C206.855 10.8929 207.461 10.6943 208.125 10.6943C208.6 10.6943 209.04 10.8213 209.443 11.0752C209.854 11.3226 210.192 11.6709 210.459 12.1201V6.9834H211.904V21.2998H210.557ZM205.996 16.124C205.996 17.4652 206.227 18.4613 206.689 19.1123C207.158 19.7633 207.702 20.0889 208.32 20.0889C208.932 20.0889 209.463 19.7764 209.912 19.1514C210.361 18.5264 210.586 17.5824 210.586 16.3193C210.586 14.8675 210.361 13.8096 209.912 13.1455C209.463 12.4749 208.896 12.1396 208.213 12.1396C207.607 12.1396 207.087 12.4619 206.65 13.1064C206.214 13.7445 205.996 14.7503 205.996 16.124Z" fill="white" />
        <path d="M201.738 17.96L203.223 18.1846C202.975 19.2913 202.526 20.1279 201.875 20.6943C201.23 21.2542 200.43 21.5342 199.473 21.5342C198.268 21.5342 197.292 21.0785 196.543 20.167C195.801 19.249 195.43 17.9274 195.43 16.2021C195.43 14.4118 195.804 13.0479 196.553 12.1104C197.308 11.1663 198.262 10.6943 199.414 10.6943C200.514 10.6943 201.432 11.1566 202.168 12.0811C202.904 13.0055 203.271 14.3434 203.271 16.0947L203.262 16.5635H196.914C196.973 17.7288 197.243 18.6077 197.725 19.2002C198.206 19.7926 198.792 20.0889 199.482 20.0889C200.563 20.0889 201.315 19.3792 201.738 17.96ZM197.002 15.1182H201.748C201.683 14.2197 201.491 13.5492 201.172 13.1064C200.71 12.4619 200.12 12.1396 199.404 12.1396C198.76 12.1396 198.21 12.4098 197.754 12.9502C197.298 13.484 197.048 14.2067 197.002 15.1182Z" fill="white" />
        <path d="M194.561 19.7275L194.756 21.2803C194.359 21.3844 194.001 21.4365 193.682 21.4365C193.18 21.4365 192.777 21.3389 192.471 21.1436C192.165 20.9417 191.953 20.6683 191.836 20.3232C191.725 19.9782 191.67 19.2913 191.67 18.2627V12.2959H190.615V10.9287H191.67V8.36035L193.105 7.30566V10.9287H194.561V12.2959H193.105V18.3604C193.105 18.9202 193.148 19.2816 193.232 19.4443C193.356 19.6787 193.587 19.7959 193.926 19.7959C194.089 19.7959 194.3 19.7731 194.561 19.7275Z" fill="white" />
        <path d="M188.721 17.501L190.137 17.7256C189.974 18.9626 189.564 19.9066 188.906 20.5576C188.255 21.2087 187.471 21.5342 186.553 21.5342C185.439 21.5342 184.521 21.0785 183.799 20.167C183.083 19.249 182.725 17.8949 182.725 16.1045C182.725 14.3011 183.086 12.9502 183.809 12.0518C184.538 11.1468 185.472 10.6943 186.611 10.6943C187.49 10.6943 188.229 10.9678 188.828 11.5146C189.434 12.0615 189.821 12.8818 189.99 13.9756L188.594 14.2393C188.457 13.5361 188.219 13.012 187.881 12.667C187.542 12.3154 187.135 12.1396 186.66 12.1396C185.951 12.1396 185.361 12.4554 184.893 13.0869C184.43 13.7119 184.199 14.708 184.199 16.0752C184.199 17.488 184.421 18.5101 184.863 19.1416C185.312 19.7731 185.885 20.0889 186.582 20.0889C187.129 20.0889 187.594 19.8805 187.979 19.4639C188.369 19.0407 188.617 18.3864 188.721 17.501Z" fill="white" />
        <path d="M179.844 17.96L181.328 18.1846C181.081 19.2913 180.632 20.1279 179.98 20.6943C179.336 21.2542 178.535 21.5342 177.578 21.5342C176.374 21.5342 175.397 21.0785 174.648 20.167C173.906 19.249 173.535 17.9274 173.535 16.2021C173.535 14.4118 173.91 13.0479 174.658 12.1104C175.413 11.1663 176.367 10.6943 177.52 10.6943C178.62 10.6943 179.538 11.1566 180.273 12.0811C181.009 13.0055 181.377 14.3434 181.377 16.0947L181.367 16.5635H175.02C175.078 17.7288 175.348 18.6077 175.83 19.2002C176.312 19.7926 176.898 20.0889 177.588 20.0889C178.669 20.0889 179.421 19.3792 179.844 17.96ZM175.107 15.1182H179.854C179.788 14.2197 179.596 13.5492 179.277 13.1064C178.815 12.4619 178.226 12.1396 177.51 12.1396C176.865 12.1396 176.315 12.4098 175.859 12.9502C175.404 13.484 175.153 14.2067 175.107 15.1182Z" fill="white" />
        <path d="M164.902 21.2998V10.9287H166.201V12.4033C166.514 11.8304 166.895 11.404 167.344 11.124C167.799 10.8376 168.32 10.6943 168.906 10.6943C169.362 10.6943 169.782 10.7855 170.166 10.9678C170.557 11.1436 170.869 11.3779 171.104 11.6709C171.344 11.9574 171.523 12.3317 171.641 12.7939C171.758 13.2562 171.816 13.9658 171.816 14.9229V21.2998H170.371V14.9912C170.371 14.2295 170.306 13.6729 170.176 13.3213C170.046 12.9697 169.84 12.6995 169.561 12.5107C169.287 12.3154 168.968 12.2178 168.604 12.2178C167.913 12.2178 167.363 12.4749 166.953 12.9893C166.543 13.5036 166.338 14.3857 166.338 15.6357V21.2998H164.902Z" fill="white" />
        <path d="M155.781 21.2998V10.9287H157.08V12.4033C157.393 11.8304 157.773 11.404 158.223 11.124C158.678 10.8376 159.199 10.6943 159.785 10.6943C160.241 10.6943 160.661 10.7855 161.045 10.9678C161.436 11.1436 161.748 11.3779 161.982 11.6709C162.223 11.9574 162.402 12.3317 162.52 12.7939C162.637 13.2562 162.695 13.9658 162.695 14.9229V21.2998H161.25V14.9912C161.25 14.2295 161.185 13.6729 161.055 13.3213C160.924 12.9697 160.719 12.6995 160.439 12.5107C160.166 12.3154 159.847 12.2178 159.482 12.2178C158.792 12.2178 158.242 12.4749 157.832 12.9893C157.422 13.5036 157.217 14.3857 157.217 15.6357V21.2998H155.781Z" fill="white" />
        <path d="M146.123 16.1143C146.123 14.3239 146.497 12.973 147.246 12.0615C148.001 11.1501 148.955 10.6943 150.107 10.6943C151.24 10.6943 152.188 11.1501 152.949 12.0615C153.711 12.973 154.092 14.2881 154.092 16.0068C154.092 17.8688 153.717 19.2555 152.969 20.167C152.22 21.0785 151.266 21.5342 150.107 21.5342C148.968 21.5342 148.018 21.0785 147.256 20.167C146.501 19.249 146.123 17.8981 146.123 16.1143ZM147.598 16.1143C147.598 17.4554 147.842 18.4548 148.33 19.1123C148.818 19.7633 149.424 20.0889 150.146 20.0889C150.824 20.0889 151.403 19.7601 151.885 19.1025C152.373 18.4385 152.617 17.4424 152.617 16.1143C152.617 14.7796 152.373 13.7868 151.885 13.1357C151.396 12.4782 150.791 12.1494 150.068 12.1494C149.385 12.1494 148.802 12.4782 148.32 13.1357C147.839 13.7933 147.598 14.7861 147.598 16.1143Z" fill="white" />
        <path d="M144.033 17.501L145.449 17.7256C145.286 18.9626 144.876 19.9066 144.219 20.5576C143.568 21.2087 142.783 21.5342 141.865 21.5342C140.752 21.5342 139.834 21.0785 139.111 20.167C138.395 19.249 138.037 17.8949 138.037 16.1045C138.037 14.3011 138.398 12.9502 139.121 12.0518C139.85 11.1468 140.785 10.6943 141.924 10.6943C142.803 10.6943 143.542 10.9678 144.141 11.5146C144.746 12.0615 145.133 12.8818 145.303 13.9756L143.906 14.2393C143.77 13.5361 143.532 13.012 143.193 12.667C142.855 12.3154 142.448 12.1396 141.973 12.1396C141.263 12.1396 140.674 12.4554 140.205 13.0869C139.743 13.7119 139.512 14.708 139.512 16.0752C139.512 17.488 139.733 18.5101 140.176 19.1416C140.625 19.7731 141.198 20.0889 141.895 20.0889C142.441 20.0889 142.907 19.8805 143.291 19.4639C143.682 19.0407 143.929 18.3864 144.033 17.501Z" fill="white" />
        <path d="M125.645 25.2939L125.488 23.6436C125.807 23.7477 126.081 23.7998 126.309 23.7998C126.615 23.7998 126.865 23.738 127.061 23.6143C127.262 23.4906 127.428 23.2985 127.559 23.0381C127.604 22.9469 127.793 22.374 128.125 21.3193L124.893 10.9287H126.445L128.223 16.9346C128.444 17.6833 128.649 18.484 128.838 19.3369C129.001 18.5296 129.196 17.7419 129.424 16.9736L131.24 10.9287H132.686L129.453 21.4756C129.03 22.8428 128.695 23.7542 128.447 24.21C128.206 24.6722 127.93 25.0042 127.617 25.2061C127.311 25.4079 126.963 25.5088 126.572 25.5088C126.299 25.5088 125.99 25.4372 125.645 25.2939Z" fill="white" />
        <path d="M122.109 20.0205C121.628 20.5218 121.126 20.8994 120.605 21.1533C120.085 21.4072 119.544 21.5342 118.984 21.5342C118.099 21.5342 117.393 21.2673 116.865 20.7334C116.338 20.1995 116.074 19.4801 116.074 18.5752C116.074 17.9762 116.198 17.4456 116.445 16.9834C116.693 16.5212 117.031 16.1598 117.461 15.8994C117.891 15.639 118.542 15.4469 119.414 15.3232C120.586 15.154 121.445 14.9521 121.992 14.7178L122.002 14.2686C122.002 13.5589 121.875 13.0609 121.621 12.7744C121.263 12.3577 120.713 12.1494 119.971 12.1494C119.3 12.1494 118.799 12.2894 118.467 12.5693C118.135 12.8428 117.891 13.3604 117.734 14.1221L116.318 13.8877C116.52 12.7744 116.927 11.9639 117.539 11.4561C118.158 10.9482 119.04 10.6943 120.186 10.6943C121.077 10.6943 121.777 10.8636 122.285 11.2021C122.793 11.5342 123.112 11.9476 123.242 12.4424C123.379 12.9372 123.447 13.6598 123.447 14.6104V16.9541C123.447 18.5947 123.477 19.6299 123.535 20.0596C123.6 20.4827 123.724 20.8962 123.906 21.2998H122.402C122.253 20.9417 122.155 20.5153 122.109 20.0205ZM121.992 16.0947C121.465 16.3551 120.68 16.5765 119.639 16.7588C119.033 16.863 118.61 16.9834 118.369 17.1201C118.128 17.2503 117.939 17.4391 117.803 17.6865C117.673 17.9274 117.607 18.2074 117.607 18.5264C117.607 19.0212 117.76 19.4183 118.066 19.7178C118.372 20.0107 118.796 20.1572 119.336 20.1572C119.85 20.1572 120.316 20.0238 120.732 19.7568C121.156 19.4899 121.471 19.1351 121.68 18.6924C121.888 18.2432 121.992 17.5921 121.992 16.7393V16.0947Z" fill="white" />
        <path d="M115.205 19.7275L115.4 21.2803C115.003 21.3844 114.645 21.4365 114.326 21.4365C113.825 21.4365 113.421 21.3389 113.115 21.1436C112.809 20.9417 112.598 20.6683 112.48 20.3232C112.37 19.9782 112.314 19.2913 112.314 18.2627V12.2959H111.26V10.9287H112.314V8.36035L113.75 7.30566V10.9287H115.205V12.2959H113.75V18.3604C113.75 18.9202 113.792 19.2816 113.877 19.4443C114.001 19.6787 114.232 19.7959 114.57 19.7959C114.733 19.7959 114.945 19.7731 115.205 19.7275Z" fill="white" />
        <path d="M100.742 16.7002L102.207 16.5439C102.285 17.3317 102.461 17.9469 102.734 18.3896C103.014 18.8258 103.411 19.1774 103.926 19.4443C104.44 19.7113 105.02 19.8447 105.664 19.8447C106.589 19.8447 107.305 19.6201 107.812 19.1709C108.327 18.7152 108.584 18.126 108.584 17.4033C108.584 16.9801 108.49 16.6123 108.301 16.2998C108.118 15.9808 107.839 15.7204 107.461 15.5186C107.09 15.3167 106.283 15.0335 105.039 14.6689C103.932 14.3434 103.145 14.0146 102.676 13.6826C102.207 13.3506 101.842 12.9307 101.582 12.4229C101.322 11.9085 101.191 11.3291 101.191 10.6846C101.191 9.52572 101.566 8.57845 102.314 7.84277C103.063 7.1071 104.072 6.73926 105.342 6.73926C106.214 6.73926 106.976 6.91178 107.627 7.25684C108.285 7.60189 108.792 8.09668 109.15 8.74121C109.515 9.38574 109.71 10.1442 109.736 11.0166L108.242 11.1533C108.164 10.2223 107.884 9.53223 107.402 9.08301C106.927 8.63379 106.257 8.40918 105.391 8.40918C104.512 8.40918 103.841 8.60775 103.379 9.00488C102.917 9.39551 102.686 9.90332 102.686 10.5283C102.686 11.1077 102.855 11.557 103.193 11.876C103.532 12.195 104.294 12.5173 105.479 12.8428C106.637 13.1618 107.451 13.4385 107.92 13.6729C108.636 14.0374 109.176 14.5192 109.541 15.1182C109.906 15.7106 110.088 16.4202 110.088 17.2471C110.088 18.0674 109.896 18.8161 109.512 19.4932C109.128 20.1637 108.604 20.6748 107.939 21.0264C107.282 21.3779 106.488 21.5537 105.557 21.5537C104.105 21.5537 102.946 21.1175 102.08 20.2451C101.221 19.3727 100.775 18.1911 100.742 16.7002Z" fill="white" />
        <rect x="101" y="44.2998" width="38" height="38" rx="19" stroke="white" stroke-opacity="0.8" stroke-width="2" />
        <g clip-path="url(#clip0_146_2000)">
          <path d="M113.115 56.8296L118.459 63.9696L113.08 69.7796H114.294L118.995 64.6929L122.799 69.7796H126.917L121.27 62.2313L126.275 56.8296H125.074L120.734 61.5079L117.234 56.8296H113.115ZM114.9 57.7163H116.79L125.144 68.8813H123.242L114.9 57.7163Z" fill="white" />
        </g>
        <rect x="166" y="44.2998" width="38" height="38" rx="19" stroke="white" stroke-opacity="0.8" stroke-width="2" />
        <path d="M191.125 64.9331V69.4248H188.593V65.4465C188.593 64.5015 188.57 63.2765 187.275 63.2765C185.957 63.2765 185.758 64.3031 185.758 65.3765V69.4248H183.203V61.2465H185.642V62.3665H185.677C186.015 61.7248 186.843 61.0481 188.08 61.0481C190.658 61.0365 191.125 62.7281 191.125 64.9331ZM181.815 58.6448C181.815 59.4615 181.15 60.1265 180.345 60.1265C179.528 60.1265 178.875 59.4498 178.875 58.6448C178.875 57.8281 179.54 57.1748 180.345 57.1748C181.162 57.1748 181.815 57.8281 181.815 58.6448ZM179.073 61.2465H181.617V69.4248H179.073V61.2465Z" fill="white" />
        <rect x="231" y="44.2998" width="38" height="38" rx="19" stroke="white" stroke-opacity="0.8" stroke-width="2" />
        <g clip-path="url(#clip1_146_2000)">
          <path d="M251.062 59.8348V61.5498H253.395L253.08 64.0348H251.062V70.2998H248.483V64.0348H246.383V61.5498H248.483V59.5898C248.483 57.4548 249.79 56.2998 251.692 56.2998C252.602 56.2998 253.383 56.3698 253.605 56.3931V58.6215H252.287C251.26 58.6215 251.062 59.1115 251.062 59.8348Z" fill="white" />
        </g>
        <rect x="296" y="44.2998" width="38" height="38" rx="19" stroke="white" stroke-opacity="0.8" stroke-width="2" />
        <g clip-path="url(#clip2_146_2000)">
          <path d="M321.603 59.8929C321.475 59.1812 320.857 58.6562 320.145 58.4929C319.072 58.2712 317.088 58.1079 314.953 58.1079C312.807 58.1079 310.8 58.2712 309.727 58.4929C309.015 58.6562 308.397 59.1462 308.268 59.8929C308.128 60.6979 308 61.8412 308 63.2996C308 64.7579 308.128 65.9012 308.292 66.7062C308.42 67.4179 309.038 67.9429 309.75 68.1062C310.882 68.3279 312.83 68.4912 314.977 68.4912C317.123 68.4912 319.072 68.3279 320.203 68.1062C320.915 67.9429 321.533 67.4529 321.662 66.7062C321.79 65.8896 321.953 64.7229 321.988 63.2996C321.93 61.8412 321.767 60.6979 321.603 59.8929ZM313.192 65.5746V61.0246L317.158 63.2996L313.192 65.5746Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_146_2000">
            <rect width="14" height="14" fill="white" transform="translate(113 56.2998)" />
          </clipPath>
          <clipPath id="clip1_146_2000">
            <rect width="14" height="14" fill="white" transform="translate(243 56.2998)" />
          </clipPath>
          <clipPath id="clip2_146_2000">
            <rect width="14" height="14" fill="white" transform="translate(308 56.2998)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};



export default NewsletterSection;
