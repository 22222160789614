/**
 * This code was generated by Builder.io.
 */
import React from "react";
import QuickLink from "./QuickLink";

const quickLinks = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c1221df606b8888317d01b374c6206fc457fc5d9f8cb7467e9fec8f7556b881?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    title: "Quickstart",
    description: "Learn about fintechain's key concepts and run starter code",
    link: "Get started",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0e2cb71fb1c350a1ba70c4f1bad20e2f16ba6c6a373982a22ec20d53f1d397f6?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    title: "API Reference",
    description:
      "Explore server-side API libraries and integrate with API endpoints",
    link: "View reference",
    href: "https://fintechain.com/docs/api/",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/baa233fb8570ecb296113dba3ece11cfc985bc1ccb3c6a03748de604c93093d6?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31",
    title: "Link",
    description:
      "Link, fintechain's client-side component, helps your users connect their accounts",
    link: "Build with Link",
    href: "https://fintechain.com/docs/link/",
  },
];

const Welcome: React.FC = () => {
  return (
    <div className="flex flex-col pt-20 max-w-full text-neutral-900 w-[1184px]">
      <div className="flex flex-wrap justify-center w-full min-h-[200px] max-md:max-w-full">
        <div className="flex flex-col grow shrink min-w-[240px] w-[442px] max-md:max-w-full">
          <h1 className="w-full text-3xl font-black leading-10 max-md:max-w-full">
            Welcome to the Docs
          </h1>
          <p className="mt-2 w-full text-2xl leading-8 max-md:max-w-full">
            Here you'll find guides, resources, and references <br /> to build
            with fintechain.
          </p>
        </div>
        <div className="flex grow shrink h-[200px] min-w-[240px] w-[522px]" />
      </div>
      <div className="flex flex-col pt-16 bg-white max-w-[1184px] w-[1184px]">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-6 justify-center items-start">
            {quickLinks.map((link, index) => (
              <QuickLink key={index} {...link} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
