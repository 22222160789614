/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="flex flex-wrap gap-4 items-center max-w-full text-sm leading-relaxed text-neutral-700 w-[1155px]">
      <Link
        to="/"
        className="flex items-start overflow-hidden self-stretch my-auto text-2xl font-bold leading-none whitespace-nowrap text-stone-900"
      >
        <img
          loading="lazy"
          src="./logo.png"
          alt=""
          className="object-contain shrink-0 w-9 aspect-square"
        />
        <span className="my-auto basis-auto">Fintechain</span>
      </Link>
      <nav className="flex flex-wrap justify-center items-start self-stretch max-md:max-w-full">
        <Link
          to="https://fintechain.org/"
          className="grow shrink self-stretch pr-2.5 py-7 min-h-[50px] w-[70px]"
        >
          About us
        </Link>
        <Link
          to="/needs"
          className="grow shrink self-stretch px-2.5 py-7 min-h-[50px] w-[90px]"
        >
          Your needs
        </Link>
        <Link
          to="/solutions"
          className="grow shrink self-stretch px-2.5 py-7 min-h-[50px] w-[110px]"
        >
          Our solutions
        </Link>
        <Link
          to="/docs"
          className="grow shrink self-stretch px-2.5 py-7 whitespace-nowrap min-h-[50px] w-[60px]"
        >
          Docs
        </Link>
        <Link
          to="/philosophy"
          className="grow shrink self-stretch px-2.5 py-7 whitespace-nowrap min-h-[50px] w-[90px]"
        >
          Philosophy
        </Link>
        <Link
          to="/join"
          className="grow shrink self-stretch px-2.5 py-7 min-h-[50px] w-[65px]"
        >
          Join
        </Link>
        <Link
          to="/contact"
          className="grow shrink self-stretch py-7 pr-2.5 pl-24 w-48 min-h-[50px] max-md:pl-5"
        >
          Contact us
        </Link>
      </nav>
      <div className="flex gap-2.5 self-stretch my-auto text-center uppercase whitespace-nowrap">
        {/* <div className="flex shrink-0 w-px bg-gray-200 h-[15px]" />
        <div className="my-auto">EN</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/778cada6f8f804f220e019009287acfcc8582936d0f74c9a2b1dddad0050cd67?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
          alt=""
          className="object-contain shrink-0 my-auto w-2.5 aspect-square"
        />
        <div className="flex shrink-0 w-px bg-gray-200 h-[15px]" /> */}
        <div className="px-3" />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_146_2223)">
          <path d="M10.7688 9.40625C11.425 8.46875 11.8 7.34375 11.8 6.125C11.8 3.03125 9.2688 0.5 6.17505 0.5C3.0813 0.5 0.550049 3.03125 0.550049 6.125C0.550049 9.21875 3.0813 11.75 6.17505 11.75C7.3938 11.75 8.5188 11.375 9.4563 10.7188L14.2375 15.5L15.55 14.1875L10.7688 9.40625ZM6.17505 9.875C4.11255 9.875 2.42505 8.1875 2.42505 6.125C2.42505 4.0625 4.11255 2.375 6.17505 2.375C8.23755 2.375 9.92505 4.0625 9.92505 6.125C9.92505 8.1875 8.23755 9.875 6.17505 9.875Z" fill="#333D3E" />
        </g>
        <defs>
          <clipPath id="clip0_146_2223">
            <rect width="15" height="15" fill="white" transform="translate(0.550049 0.5)" />
          </clipPath>
        </defs>
      </svg>
      <div className="flex items-start self-stretch my-auto text-center text-teal-100 whitespace-nowrap">
        <div className="flex gap-4 justify-center items-center px-3 py-1.5 bg-neutral-700 min-h-[34px] rounded-[50px]">
          <svg className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4613 5.95583V3.64472L10.6835 1.86694H6.32792L4.55015 3.64472V5.95583H2.15015V15.7336H14.9501V5.95583H12.4613ZM5.52792 4.00028L6.77237 2.75583H10.3279L11.5724 4.00028V5.95583H5.52792V4.00028ZM14.0613 14.8447H3.03904V6.84472H14.0613V14.8447Z" fill="#CFFBF2" />
            <path d="M10.061 10.3114C10.061 11.1114 9.34993 11.8225 8.54993 11.8225C7.74993 11.8225 7.03882 11.1114 7.03882 10.3114C7.03882 9.5114 7.74993 8.80029 8.54993 8.80029C9.34993 8.80029 10.061 9.5114 10.061 10.3114Z" fill="#CFFBF2" />
          </svg>

          <div className="self-stretch my-auto">Fintechain</div>
          <div className="self-stretch my-auto">{""}</div>
        </div>
      </div>
    </header>
  );
};

export default Header;
