/**
 * This code was generated by Builder.io.
 */
import React from "react";

const PartnerLogos: React.FC = () => {
  return (
    <section className="flex justify-center items-center py-6 mt-16 border-t border-b border-solid bg-cyan-400 bg-opacity-40 border-y-neutral-400 min-h-[120px] max-md:mt-10">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/22c1c8349427e2804e1eb92be9481383391c3e76c24017aac911abc1bbb5c84d?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
        alt="Partner logos"
        className="object-contain flex-1 shrink self-stretch my-auto aspect-[27.03] basis-0 min-w-[240px] w-[1920px] max-md:max-w-full"
      />
    </section>
  );
};

export default PartnerLogos;
