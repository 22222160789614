import React from 'react';
import SocialIcons from './SocialIcons';
import CompanyLinks from './CompanyLinks';
import SupportLinks from './SupportLinks';
import EmailSubscription from './EmailSubscription';
import ContactInfo from './ContactInfo';
import FooterLinks from './FooterLinks';

interface FooterProps {
  // Add any props if needed
}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="flex flex-col px-8 md:px-16 lg:px-24 xl:px-32 2xl:px-48 mt-16 w-full bg-neutral-700 min-h-[347px] max-md:px-5 max-md:mt-10">
      <div className="flex flex-wrap items-start justify-between w-full mt-6">
        {/* 左侧：Fintechain Logo 和 Social Icons */}
        <div className="flex flex-col w-full md:w-[30%]">
          <div className="flex items-center">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6464c91fe16481b3bf96a556526bf0a490d8096a77bf439d5878f0a95140d7e?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
              className="object-contain w-6 h-6"
              alt="Fintechain logo"
            />
            <h2 className="ml-2 text-3xl text-cyan-400">Fintechain</h2>
          </div>
          <div className="flex flex-col items-start mt-4">
            <p className="text-xs text-stone-300">Copyright © 2020 Landify UI Kit.</p>
            <p className="mt-2 text-xs text-stone-300">All rights reserved</p>
            <SocialIcons />
          </div>
        </div>

        {/* 右侧：CompanyLinks, SupportLinks, EmailSubscription */}
        <div className="flex w-full md:w-[70%] justify-between flex-wrap gap-16 mt-6 md:mt-0">
          <CompanyLinks />
          <SupportLinks />
          <EmailSubscription />
        </div>
      </div>
      
      <ContactInfo />
      <FooterLinks />
    </footer>
  );
};

export default Footer;
