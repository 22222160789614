import React from 'react';

interface SocialLinkProps {
  text: string;
  onClick?: () => void;
}

const SocialLink: React.FC<SocialLinkProps> = ({ text, onClick }) => {
  return (
    <div 
        onClick={onClick} // 添加点击事件
        className="flex flex-1 gap-2 items-center rounded-[50px] cursor-pointer">
      <span className="self-stretch my-auto text-sm font-bold leading-none text-neutral-700">{text}</span>
      <div className="flex flex-col self-stretch my-auto min-h-[11px] w-[7px]">
        <div className="flex overflow-hidden flex-col justify-center items-center min-h-[11px] w-[7px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
            <g clip-path="url(#clip0_273_408)">
              <path d="M1.71992 11.7698C1.61992 11.7698 1.41992 11.6698 1.31992 11.5698C1.11992 11.3698 1.11992 10.9698 1.31992 10.7698L5.81992 6.26982L1.31992 1.76982C1.11992 1.56982 1.11992 1.16982 1.31992 0.969824C1.61992 0.769824 1.91992 0.769824 2.11992 0.969824L7.01992 5.86982C7.21992 6.06982 7.21992 6.46982 7.01992 6.66982L2.11992 11.5698C2.01992 11.6698 1.91992 11.7698 1.71992 11.7698Z" fill="#333D3E" />
            </g>
            <defs>
              <clipPath id="clip0_273_408">
                <rect width="7" height="11" fill="white" transform="translate(0.219971 0.77002)" />
              </clipPath>
            </defs>
          </svg>        </div>
      </div>
    </div>
  );
};


interface InfoItemProps {
  iconSrc: string;
  text: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ iconSrc, text }) => {
  return (
    <div className="flex flex-auto gap-6">
      <div className="flex flex-col self-start">
        <img loading="lazy" src={iconSrc} alt="" className="object-contain aspect-square w-[26px]" />
      </div>
      <p className="flex-auto text-base text-black">{text}</p>
    </div>
  );
};


interface InfoItemData {
  iconSrc: string;
  text: string;
}

interface SocialLinkData {
  text: string;
  iconSrc: string;
}

const SocialPanl: React.FC = () => {
  const infoItems: InfoItemData[] = [
    {
      iconSrc: "./xtwitter.png",
      text: "We are excited to collaborate with banks and financial institutions in developing products that will truly transform the financial infrastructure."
    },
    {
      iconSrc: "./inlinking.png",
      text: "We look forward to meeting you at the Finnovate sessions during Money20/20"
    }
  ];

  const socialLinks: SocialLinkData[] = [
    {
      text: "Follow us on X (Twitter)",
      iconSrc: "https://x.com/0xYellow/status/1836091736084615437",
    },
    {
      text: "Follow us on LinkedIn",
      iconSrc: "https://www.linkedin.com/company/104791244/admin/dashboard/"
    }
  ];

  return (
    <section className="flex flex-col px-20 py-16 mt-14 max-w-full mx-auto bg-cyan-400 bg-opacity-40 w-[1142px] max-md:px-5 max-md:mt-10">
      <div className="flex flex-wrap gap-10 max-w-full">
        {infoItems.map((item, index) => (
          <InfoItem key={index} iconSrc={item.iconSrc} text={item.text} />
        ))}
      </div>
      <div className="flex flex-wrap gap-10 self-center mt-20 max-w-full w-[827px] max-md:mt-10">
        {socialLinks.map((link, index) => (

          <SocialLink
            key={index}
            text={link.text}
            onClick={() => window.open(link.iconSrc, '_blank')} // 跳转到外部链接
          />))}
      </div>
    </section>
  );
};


export default SocialPanl;
