import React from 'react';

const SocialIcons: React.FC = () => {
  const icons = [
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/266429071ddbdd0119cca8a8338b6e48c5bb01b6c181b213ef3042778c61aae2?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31", alt: "Social icon 1" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/f30153dd194b5ce32c4b82c77658a3932bdc73fbb141d3c59edbd9d241019ff5?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31", alt: "Social icon 2" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b71a337d38fddf92c503ba7586ace35344366bf5481b9d2beb31eff04cbb3fc1?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31", alt: "Social icon 3" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/800d7ba5d30ec50b5624a0d6ba495240ab9d4f53a221006bf02ca9b7e6bc1a2d?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31", alt: "Social icon 4" }
  ];

  return (
    <div className="flex overflow-hidden gap-1.5 items-start mt-5 h-3.5 bg-neutral-700">
      {icons.map((icon, index) => (
        <img key={index} loading="lazy" src={icon.src} className="object-contain shrink-0 aspect-[1.1] w-[11px]" alt={icon.alt} />
      ))}
    </div>
  );
};

export default SocialIcons;