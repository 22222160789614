/**
 * This code was generated by Builder.io.
 */
import React from "react";

const HeroSection: React.FC = () => {
  return (
    <section className="flex flex-col justify-center items-center px-16 py-2 mt-12 w-full bg-neutral-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="w-full max-w-[1475px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto w-full font-semibold max-md:mt-10 max-md:max-w-full">
              <h2 className="text-7xl tracking-tighter leading-[80px] min-h-[466px] text-neutral-900 max-md:max-w-full max-md:text-4xl max-md:leading-10">
                The most practical solutions for banks and financial
                institutions
              </h2>
              <div className="flex gap-5 justify-between mt-9 ml-24 max-w-full text-xl tracking-tighter leading-8 w-[383px] max-md:ml-2.5">
                <button className="flex gap-3 justify-center items-center px-7 py-3.5 text-white border border-solid bg-neutral-900 border-neutral-900 min-h-[56px] rounded-[240px] max-md:px-5">
                  <span className="self-stretch my-auto">Get started</span>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5e613ae4742e460ca426f02c376a01fac1326cfc4a2d262b73f0dc4c40c8dc79?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                  />
                </button>
                <button className="self-stretch px-7 py-3.5 bg-white border border-solid border-neutral-900 min-h-[56px] rounded-[240px] text-neutral-900 max-md:px-5">
                  Contact sales
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c023443ea15505d0baf5ee409bbc37aab10ade3855337218ae0912b6803c839?placeholderIfAbsent=true&apiKey=36731b1cb0dd4b3b98c83e6a26559d31"
              alt="Financial solutions illustration"
              className="object-contain w-full aspect-square max-md:mt-10 max-md:max-w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
